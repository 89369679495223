<template>
  <el-dialog width="1300px" title="Edit Questionnaire" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" autocomplete="off" label-position="top">
      <div v-for="(data, idx) in quJSON" :key="data.title">
        <el-form-item :label="idx + 1 + '. ' + data.title" :prop="data.model">
          <el-row v-if="data.type === 'textarea'">
            <el-col :lg="11" :xl="8">
              <el-input
                v-model="dataForm[data.model]"
                type="textarea"
                :rows="5"
                :autosize="{ minRows: 4 }"
                maxlength="78"
                show-word-limit
                resize="none"
              ></el-input>
            </el-col>
          </el-row>
          <el-row v-if="data.type === 'input'">
            <el-col :lg="11" :xl="8">
              <el-input v-model="dataForm[data.model]" type="input"></el-input>
            </el-col>
          </el-row>
          <multiple-box v-if="data.type === 'multiplebox'" :options="data.options" v-model="dataForm[data.model]" />
          <el-radio-group v-if="data.type === 'radio'" v-model="dataForm[data.model]">
            <el-radio v-for="item in data.options" :key="item.value" :label="item.value">{{ item.title }}</el-radio>
          </el-radio-group>
          <el-radio-group
            v-if="dataForm[data.model] && data.type === 'radio-input'"
            v-model="dataForm[data.model][data.secondary]"
            class="flex align-center"
          >
            <el-radio v-for="item in data.options" :key="item.value" :label="item.value" class="flex align-center">
              <div class="flex align-center">
                {{ item.title }}
                <div v-if="item.other && item.other.type == 'input' && dataForm[data.model][data.secondary] == item.value" class="ml8">
                  <el-input v-model="dataForm[data.model][item.other.model]" type="input"></el-input>
                </div>
              </div>
            </el-radio>
          </el-radio-group>
          <el-row>
            <el-col :lg="10" :xl="8">
              <el-select style="width: 100%" v-if="data.type === 'country'" multiple v-model="dataForm[data.model]" filterable>
                <el-option v-for="item in countryList" :key="item.value" :label="item.title" :value="item.value">{{ item.title }}</el-option>
                <el-option label="N/A" value="NONE" v-if="businessGenre == 'COMPANY'">N/A</el-option>
              </el-select>
            </el-col>
          </el-row>
          <div v-if="data.type === 'table'">
            <el-button type="primary" class="mb20" @click="addMember(data.model)">Add beneficial owner</el-button>
            <el-table :data="dataForm[data.model]" :key="Math.random()" border>
              <el-table-column prop="memberType" label="Type" align="center">
                <template slot-scope="scope">
                  <p v-if="scope.row.memberType == 'CORPORATE'">Company</p>
                  <p v-if="scope.row.memberType == 'INDIVIDUAL'">Individual</p>
                </template>
              </el-table-column>
              <el-table-column prop="fullName" label="Name" align="center"></el-table-column>
              <el-table-column prop="nationality" label="Country / Nationality" align="center"></el-table-column>
              <el-table-column prop="phone" label="Mobile" align="center"></el-table-column>
              <el-table-column prop="email" label="Email" align="center"></el-table-column>
              <el-table-column label="Operation" align="center">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="updateHandle(scope.$index, data.model, scope.row)">Edit</el-button>
                  <el-button type="text" size="small" @click="deleteMember(scope.$index, data.model)">Delete</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-if="data.type === 'tableList'">
            <el-button type="primary" class="mb20" @click="addMember(data.model)">Add fund</el-button>
            <el-table :data="fundInfoList" :key="Math.random()" border>
              <el-table-column prop="fundName" label="Proposed Fund Name" align="center"></el-table-column>
              <el-table-column prop="fundType" label="Type" align="center"></el-table-column>
              <el-table-column prop="fundRegistrationCountry" label="Registration Country" align="center"></el-table-column>
              <el-table-column prop="fundRegistration" label="Registration address" align="center"></el-table-column>
              <el-table-column label="Operation" align="center">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="updateHandle(scope.$index, data.model, scope.row)">Edit</el-button>
                  <el-button type="text" size="small" @click="deleteMember(scope.$index, data.model)">Delete</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
      </div>

      <!-- 公司注册地址 -->
      <div v-if="businessType == 'SERVICE_TRANSFER'">
        <el-form-item :label="`${quJSON.length + 1}. Registered Office`">
          <el-radio-group v-model="registeredForm.registeredOfficeType">
            <div v-for="item in registerOfficeTypeList" :key="item.value">
              <el-radio class="pt15 pb15" :label="item.value">
                <span>{{ item.title }}</span>
                <div class="mt15 ml24" v-if="item.value === 1 && registeredForm.registeredOfficeType === 1">
                  <el-radio-group v-model="registeredForm.registeredOfficeId">
                    <el-radio v-for="office in nativeRegisteredAddress" :key="office.id" :label="office.id">{{ formatNativeAddress(office) }}</el-radio>
                  </el-radio-group>
                </div>
                <div class="mt10 ml24" v-if="item.value === 2 && registeredForm.registeredOfficeType === 2">
                  <el-row :gutter="20">
                    <el-col :span="9">
                      <el-form-item label="Address">
                        <el-input v-model="registeredForm.registeredOfficeAddress" :oninput="isEn"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="5">
                      <el-form-item label="City">
                        <el-input v-model="registeredForm.registeredOfficeCity" :oninput="isEn"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="5">
                      <el-form-item label="Country">
                        <!-- <el-input v-model="registeredOfficeCountry" :oninput="isEn"></el-input> -->
                        <el-select v-model="registeredForm.registeredOfficeCountry" placeholder="Select Country" filterable>
                          <el-option v-for="item in countryList" :key="item.value" :label="item.title" :value="item.value"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="5">
                      <el-form-item label="Code">
                        <el-input v-model="registeredForm.registeredOfficePostalCode" :oninput="isEn"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </el-radio>
            </div>
          </el-radio-group>
        </el-form-item>
      </div>

      <!-- 我们同意聘用由星中商务推荐的名义董事1名，我们也理解该名义董事的行为造成的任何法律影响均与EStar无关。 -->
      <template v-if="(businessType == 'SERVICE_TRANSFER' && isNeedNomineeDirector) || businessType == 'COMPREHENSIVE_CUSTOMIZED'">
        <el-form-item label="Nominee Director Service">
          <el-checkbox class="checkboxCustom" :true-label="1" :false-label="0" v-model="hasNomineeDirector">
            We consent to appoint one Nominee Director recommended by EStar Business Services Pte. Ltd. ("EStar"), and we understand that any legal impact
            caused by the Nominee Director's action has nothing to do with EStar.
          </el-checkbox>
        </el-form-item>
      </template>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">OK</el-button>
    </span>

    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update :businessType="businessType" v-if="addOrUpdateVisible" ref="addOrUpdate" @change="doUpdateMember"></add-or-update>
    <!-- 基金信息 -->
    <update-fund v-if="updateFundVisible" ref="updateFund" @change="doUpdateFund"></update-fund>
  </el-dialog>
</template>

<script>
import AddOrUpdate from "./AddOrUpdate";
import UpdateFund from "./UpdateFund";
import { mapState, mapActions } from "vuex";
export default {
  props: {
    applymentId: {
      type: String,
    },
    quJSON: {
      type: Array,
      default: function () {
        return [];
      },
    },
    businessType: {
      type: String,
    },
    businessGenre: {
      type: String,
    },
    registerOfficeTypeList: {
      type: Array,
    },
    nativeRegisteredAddress: {
      type: Array,
    },
    isNeedNomineeDirector: {
      type: Boolean,
    },
  },
  components: {
    AddOrUpdate,
    UpdateFund,
  },
  data() {
    return {
      visible: false,
      addOrUpdateVisible: false,
      updateFundVisible: false,
      dataForm: {},
      fundInfoList: [],
      registeredForm: {
        registeredOfficeType: null,
        registeredOfficeId: null,
        registeredOfficeAddress: null,
        registeredOfficeCity: null,
        registeredOfficeCountry: null,
        registeredOfficePostalCode: null,
      },
      fundUpdateRecord: {
        applymentId: null,
        replacements: {},
      },
      hasNomineeDirector: null,
    };
  },
  computed: {
    ...mapState("constant", ["countryList", "businessStepObj", "companyMemberTypes"]),
  },
  methods: {
    ...mapActions("applyment", ["initApplyment", "doSaveRevising"]),
    formatNativeAddress(office) {
      return office.address + " ," + office.city + " ," + office.country + " ," + office.postalCode;
    },
    // 初始化
    async init(questionnaire, fundInfoList, registeredForm, hasNomineeDirector) {
      // 重置基金信息修改记录
      this.fundUpdateRecord = {
        applymentId: this.applymentId,
        replacements: {},
      };
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      this.dataForm = JSON.parse(JSON.stringify(questionnaire));
      this.fundInfoList = JSON.parse(JSON.stringify(fundInfoList));
      this.registeredForm = JSON.parse(JSON.stringify(registeredForm));
      this.hasNomineeDirector = hasNomineeDirector;
    },
    // 提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid) {
          await this.updateBatchMembersFundsHttp();
          this.submitData();
        }
      });
    },
    async submitData() {
      const submitData = {
        id: this.applymentId,
        questionnaire: this.dataForm,
        procedure: {
          step: this.businessStepObj[this.businessType]["kycQuestionnaire"],
        },
        fundInfoList: this.fundInfoList,
        ...this.registeredForm,
        hasNomineeDirector: this.hasNomineeDirector,
      };
      this.doSaveRevising(submitData)
        .then(() => {
          // 更新外部视图
          this.initApplyment(this.applymentId);
          this.$message({
            message: "success",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.visible = false;
            },
          });
        })
        .catch(msg => {
          this.$message.error(msg);
        });
    },
    // 批量更新成员基金信息
    updateBatchMembersFundsHttp() {
      return new Promise((resolve, reject) => {
        if (Object.keys(this.fundUpdateRecord.replacements).length == 0) {
          resolve(true);
        } else {
          this.$http.post("/admin/companyMemberManage/updateBatchMembersFunds", this.fundUpdateRecord).then(({ code, data, msg }) => {
            if (code === 200) {
              resolve(data);
            } else {
              this.$message.error(msg);
              reject(msg);
            }
          });
        }
      });
    },
    // 增加 - table
    async addMember(paramName) {
      if (paramName == "fundInfoList") {
        this.updateFundVisible = true;
        await this.$nextTick();
        this.$refs.updateFund.init("add", null);
      } else {
        this.addOrUpdateVisible = true;
        await this.$nextTick();
        this.$refs.addOrUpdate.init("add", paramName, null);
      }
    },
    // 修改 - table
    async updateHandle(idx, paramName, row) {
      if (paramName == "fundInfoList") {
        this.updateFundVisible = true;
        await this.$nextTick();
        this.$refs.updateFund.init(idx, row);
      } else {
        this.addOrUpdateVisible = true;
        await this.$nextTick();
        this.$refs.addOrUpdate.init(idx, paramName, row);
      }
    },
    // 更新基金信息修改记录
    setFundRecord(id, oldVal, newVal) {
      if (!this.fundUpdateRecord.replacements[id]) {
        this.fundUpdateRecord.replacements[id] = {};
        this.fundUpdateRecord.replacements[id].oldFundName = oldVal;
      }
      this.fundUpdateRecord.replacements[id].newFundName = newVal;
    },
    // 删除
    async deleteMember(idx, paramName) {
      if (paramName == "fundInfoList") {
        const fundInfoListCopy = this.fundInfoList[idx];
        this.setFundRecord(fundInfoListCopy.id, fundInfoListCopy.fundName, null);
        // 更新视图
        this.fundInfoList.splice(idx, 1);
      } else {
        this.dataForm[paramName].splice(idx, 1);
      }
    },
    // 更新 - 子组件触发(公司的最终受益人信息)
    async doUpdateMember(obj, introduction) {
      if (introduction.idx == "add") {
        this.dataForm[introduction.paramName].push(JSON.parse(JSON.stringify(obj)));
      } else {
        this.dataForm[introduction.paramName][introduction.idx] = JSON.parse(JSON.stringify(obj));
      }
      this.$forceUpdate();
      this.addOrUpdateVisible = false;
    },
    // 更新 - 子组件触发(基金信息)
    async doUpdateFund(obj, idx) {
      let objCopy = JSON.parse(JSON.stringify(obj));
      if (idx == "add") {
        this.fundInfoList.push(objCopy);
      } else {
        const fundInfoListCopy = this.fundInfoList[idx];
        this.setFundRecord(fundInfoListCopy.id, fundInfoListCopy.fundName, objCopy.fundName);
        // 更新视图
        this.$set(this.fundInfoList, idx, objCopy);
      }
      this.updateFundVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.checkboxCustom {
  ::v-deep .el-checkbox__label {
    /* 这两个在技术上是一样的, 为了兼容了浏览器两个都加上 */
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;

    -ms-word-break: break-all !important;
    /* 这个的使用在web-kit中有些危险，他可能会阶段所有东西 */
    word-break: break-all !important;
    /* Instead use this non-standard one: */
    word-break: break-word !important;

    /* 如果浏览器支持的话增加一个连接符(Blink不支持) */
    hyphens: auto !important;

    display: inline-grid;
    white-space: pre-line;
    line-height: 20px;
  }
}
</style>
