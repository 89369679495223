<template>
  <div class="desp">
    <div class="desp-header">
      <div class="label">KYC Questionnaire</div>
      <div class="opts">
        <el-button
          v-if="
            (applymentStatus === 'REVISING' ||
              applymentStatus === 'CORRECTED' ||
              applymentStatus === 'ILLEGAL' ||
              applymentStatus === 'AUDIT_FAIL' ||
              applymentStatus === 'REFUND_APPLY_DENY' ||
              applymentStatus === 'REFUND_APPLY_REJECT' ||
              applymentStatus === 'ACRA_REJECTED') &&
            isApplymentAuth('company:coordinate')
          "
          @click="openEdit"
        >
          Edit
        </el-button>
      </div>
    </div>
    <el-form class="no-event" :model="questionnaire" label-position="top">
      <div v-for="(data, idx) in quJSON" :key="`questionnaire${idx}${data.title}`">
        <el-form-item :label="idx + 1 + '. ' + data.title" :prop="data.model">
          <el-row v-if="data.type === 'textarea'">
            <el-col :lg="11" :xl="8">
              <el-input
                readonly
                :value="questionnaire[data.model]"
                type="textarea"
                :rows="5"
                :autosize="{ minRows: 4 }"
                maxlength="78"
                show-word-limit
                resize="none"
                :oninput="isEn"
              ></el-input>
            </el-col>
          </el-row>
          <el-row v-if="data.type === 'input'">
            <el-col :lg="11" :xl="8">
              <el-input readonly :value="questionnaire[data.model]" type="input"></el-input>
            </el-col>
          </el-row>
          <multiple-box v-if="data.type === 'multiplebox'" :options="data.options" :value="questionnaire[data.model]" readonly />
          <el-radio-group v-if="data.type === 'radio'" :value="questionnaire[data.model]">
            <el-radio v-for="item in data.options" :key="`radio${item.value}`" :label="item.value">{{ item.title }}</el-radio>
          </el-radio-group>
          <el-radio-group
            v-if="questionnaire[data.model] && data.type === 'radio-input'"
            :value="questionnaire[data.model][data.secondary]"
            class="flex align-center"
          >
            <el-radio v-for="item in data.options" :key="`radio-input${item.value}`" :label="item.value" class="flex align-center">
              <div class="flex align-center">
                {{ item.title }}
                <div v-if="item.other && item.other.type == 'input' && questionnaire[data.model][data.secondary] == item.value" class="ml8">
                  <el-input readonly :value="questionnaire[data.model][item.other.model]" type="input"></el-input>
                </div>
              </div>
            </el-radio>
          </el-radio-group>
          <el-row>
            <el-col :lg="10" :xl="8">
              <el-select style="width: 100%" v-if="data.type === 'country'" multiple :value="questionnaire[data.model]">
                <el-option v-for="item in nationalityList" :key="`country${item.value}`" :label="item.title" :value="item.value">
                  {{ item.title }}
                </el-option>
                <el-option label="N/A" value="NONE" v-if="businessGenre == 'COMPANY'">N/A</el-option>
              </el-select>
            </el-col>
          </el-row>
          <div v-if="data.type === 'table'">
            <div v-for="(detail, sy) in questionnaire[data.model]" :key="`table${sy}${detail.fullName}`" class="pt20 pb20 shareholder-wrap">
              <!-- <div class="member-type">{{ memberTypeFormat(detail.memberType) }}</div> -->
              <div class="position-held">{{ shareholdersFormat(detail.memberType) }}</div>
              <div class="base-info mt6">
                <el-descriptions
                  :labelStyle="{ color: '#667288', background: '#ECEFF9' }"
                  :contentStyle="{ background: '#ECEFF9' }"
                  :column="3"
                  direction="vertical"
                >
                  <el-descriptions-item label="Name (same as business certificate)" v-if="detail.memberType === 'CORPORATE'">
                    {{ detail.fullName }}
                  </el-descriptions-item>
                  <el-descriptions-item label="Name (same as IC)" v-if="detail.memberType === 'INDIVIDUAL'">{{ detail.fullName }}</el-descriptions-item>
                  <el-descriptions-item v-if="detail.memberType === 'CORPORATE'" label="Country">{{ detail.nationality }}</el-descriptions-item>
                  <el-descriptions-item v-if="detail.memberType === 'INDIVIDUAL'" label="Nationality">{{ detail.nationality }}</el-descriptions-item>
                  <el-descriptions-item v-if="detail.memberType === 'CORPORATE'" label="Business No.">
                    {{ detail.businessLicenseNo }}
                  </el-descriptions-item>
                  <el-descriptions-item v-if="detail.memberType === 'INDIVIDUAL'" label="Identification Type">
                    {{ detail.identificationType }}
                  </el-descriptions-item>
                  <el-descriptions-item v-if="detail.memberType === 'INDIVIDUAL'" label="Identification No.">
                    {{ detail.identificationNo }}
                  </el-descriptions-item>
                  <el-descriptions-item label="Mobile">{{ detail.phone }}</el-descriptions-item>
                  <el-descriptions-item label="Email">{{ detail.email }}</el-descriptions-item>
                  <el-descriptions-item v-if="detail.memberType === 'CORPORATE'" label="Registered Address">
                    {{ detail.registeredOfficeAddress }}
                  </el-descriptions-item>
                  <el-descriptions-item v-if="detail.memberType === 'INDIVIDUAL'" label="Residential address">
                    {{ detail.residentialAddress }}
                  </el-descriptions-item>
                  <el-descriptions-item v-if="detail.memberType === 'CORPORATE'" label="Operation Address">
                    {{ detail.operationAddress }}
                  </el-descriptions-item>
                  <el-descriptions-item v-if="detail.memberType === 'CORPORATE'" label="Incorporation Date">
                    {{ detail.incorporationDate }}
                  </el-descriptions-item>
                  <el-descriptions-item v-if="detail.memberType === 'INDIVIDUAL'" label="Birth Date">
                    {{ detail.birthDate }}
                  </el-descriptions-item>
                  <template v-if="detail.memberType === 'INDIVIDUAL'">
                    <el-descriptions-item label="Does the individual hold share interest of any entity in PRC inland?">
                      {{ getBooleanFormat(detail.isHoldShareOfPrcInland) }}
                    </el-descriptions-item>
                    <el-descriptions-item label="Please provide the name of RPC entity (1 entity is required)" v-if="detail.isHoldShareOfPrcInland">
                      {{ detail.holdShareOfPrcEntity }}
                    </el-descriptions-item>
                  </template>
                </el-descriptions>
              </div>
              <!-- 公司类型下的股东 -->
              <template v-if="detail.memberType === 'CORPORATE'">
                <!-- representative (主体代表) -->
                <div class="mt25" v-if="detail.representativeInfo">
                  <div class="position-held">Representative</div>
                  <div class="base-info mt20">
                    <el-descriptions
                      :labelStyle="{ color: '#667288', background: '#ECEFF9' }"
                      :contentStyle="{ background: '#ECEFF9' }"
                      :column="3"
                      direction="vertical"
                    >
                      <el-descriptions-item label="Name (same as IC)">
                        {{ detail.representativeInfo.fullName }}
                      </el-descriptions-item>
                      <el-descriptions-item label="Nationality">{{ detail.representativeInfo.nationality }}</el-descriptions-item>
                      <el-descriptions-item label="Identification Type">
                        {{ detail.representativeInfo.identificationType }}
                      </el-descriptions-item>
                      <el-descriptions-item label="Identification No.">
                        {{ detail.representativeInfo.identificationNo }}
                      </el-descriptions-item>
                      <el-descriptions-item label="Mobile">{{ detail.representativeInfo.phone }}</el-descriptions-item>
                      <el-descriptions-item label="Email">{{ detail.representativeInfo.email }}</el-descriptions-item>
                      <el-descriptions-item label="Residential address">
                        {{ detail.representativeInfo.residentialAddress }}
                      </el-descriptions-item>
                      <el-descriptions-item label="Birth Date">
                        {{ detail.representativeInfo.birthDate }}
                      </el-descriptions-item>
                    </el-descriptions>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div v-if="data.type === 'tableList'">
            <el-table :data="fundInfoList" :key="Math.random()" border>
              <el-table-column prop="fundName" label="Proposed Fund Name" align="center"></el-table-column>
              <el-table-column prop="fundType" label="Type" align="center"></el-table-column>
              <el-table-column prop="fundRegistrationCountry" label="Registration Country" align="center"></el-table-column>
              <el-table-column prop="fundRegistration" label="Registration address" align="center"></el-table-column>
            </el-table>
          </div>
        </el-form-item>
      </div>

      <!-- 公司注册地址 -->
      <div v-if="businessType == 'SERVICE_TRANSFER'">
        <el-form-item :label="`${quJSON.length + 1}. Registered Office`">
          <el-radio-group :value="registeredOfficeType">
            <div v-for="item in registerOfficeTypeList" :key="`Registered${item.value}`">
              <el-radio class="pt15 pb15" :label="item.value">
                <span>{{ item.title }}</span>
                <div class="mt15 ml24" v-if="item.value === 1 && registeredOfficeType === 1">
                  <el-radio-group :value="registeredOfficeId">
                    <el-radio v-for="office in nativeRegisteredAddress" :key="`Office${office.id}`" :label="office.id">
                      {{ formatNativeAddress(office) }}
                    </el-radio>
                  </el-radio-group>
                </div>
                <div class="mt10 ml24" v-if="item.value === 2 && registeredOfficeType === 2">
                  {{ formatCustomAddress() }}
                </div>
              </el-radio>
            </div>
          </el-radio-group>
        </el-form-item>
      </div>

      <!-- 我们同意聘用由星中商务推荐的名义董事1名，我们也理解该名义董事的行为造成的任何法律影响均与EStar无关。 -->
      <div
        class="no-event desp-items-group"
        id="engageNomineeDirectorCheckbox"
        v-if="(businessType == 'SERVICE_TRANSFER' && isNeedNomineeDirector) || businessType == 'COMPREHENSIVE_CUSTOMIZED'"
      >
        <div class="desp-items-group-title">Nominee Director Service</div>
        <div class="desp-item">
          <div class="desp-item-self">
            <el-checkbox class="checkboxCustom" :true-label="1" :false-label="0" v-model="hasNomineeDirector">
              We consent to appoint one Nominee Director recommended by EStar Business Services Pte. Ltd. ("EStar"), and we understand that any legal
              impact caused by the Nominee Director's action has nothing to do with EStar.
            </el-checkbox>
          </div>
        </div>
      </div>
    </el-form>
    <!-- 弹窗 -->
    <edit-modal
      :applymentId="applymentId"
      :quJSON="quJSON"
      :businessType="businessType"
      :businessGenre="businessGenre"
      :registerOfficeTypeList="registerOfficeTypeList"
      :nativeRegisteredAddress="nativeRegisteredAddress"
      :isNeedNomineeDirector="isNeedNomineeDirector"
      v-if="editVisible"
      ref="editModal"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import DataJson from "./data.json";
import EditModal from "./modal/Edit.vue";
export default {
  components: {
    EditModal,
  },
  props: {
    applymentId: {
      type: String,
    },
    applymentStatus: {
      type: String,
    },
    questionnaire: {
      type: Object,
      default: function () {
        return {};
      },
    },
    businessType: {
      type: String,
    },
    fundInfoList: {
      type: Array,
    },
    businessGenre: {
      type: String,
    },
    registeredOfficeType: {
      type: Number || String,
    },
    registeredOfficeAddress: {
      type: String,
    },
    registeredOfficeCity: {
      type: String,
    },
    registeredOfficeCountry: {
      type: String,
    },
    registeredOfficePostalCode: {
      type: String,
    },
    registeredOfficeId: {
      type: String,
    },
    nativeRegisteredAddress: {
      type: Array,
      default: function () {
        return [];
      },
    },
    isNeedNomineeDirector: {
      type: Boolean,
    },
    hasNomineeDirector: {
      type: Number,
    },
  },
  data() {
    return {
      quJSON: [],
      editVisible: false,
      shareholdersTypes: [
        {
          title: "Information of Individual Shareholder",
          value: "INDIVIDUAL",
        },
        {
          title: "Information of Corporate Shareholder",
          value: "CORPORATE",
        },
      ],
      registerOfficeTypeList: [
        {
          title: `Consent to use EStar Business Services Pte. Ltd. ("EStar")'s registration address`,
          value: 1,
        },
        {
          title: "Use other registration address",
          value: 2,
        },
      ],
    };
  },
  computed: {
    ...mapState("constant", ["companyMemberTypes", "nationalityList", "countryList"]),
    nativeCountryList() {
      return this.countryList.filter(i => i.native);
    },
  },
  async mounted() {
    // this.quJSON = DataJson;
    const dataJsonCopy = JSON.parse(JSON.stringify(DataJson));
    this.quJSON = dataJsonCopy.filter(item => (item.classify && item.classify.includes(this.businessType)) || !item.classify);
  },
  methods: {
    getBooleanFormat(v) {
      if (v == 1) {
        return "Yes";
      } else if (v == 0) {
        return "No";
      } else {
        return null;
      }
    },
    memberTypeFormat(v) {
      const one = this.companyMemberTypes.find(i => i.value === v);
      return one?.title;
    },
    shareholdersFormat(v) {
      const one = this.shareholdersTypes.find(i => i.value === v);
      return one?.title;
    },
    formatNativeAddress(office) {
      return office.address + " ," + office.city + " ," + office.country + " ," + office.postalCode;
    },
    formatCustomAddress() {
      return (
        this.registeredOfficeAddress + " ," + this.registeredOfficeCity + " ," + this.registeredOfficeCountry + " ," + this.registeredOfficePostalCode
      );
    },
    async openEdit() {
      this.editVisible = true;
      await this.$nextTick();
      let registeredForm = {
        registeredOfficeType: this.registeredOfficeType,
        registeredOfficeId: this.registeredOfficeId,
        registeredOfficeAddress: this.registeredOfficeAddress,
        registeredOfficeCity: this.registeredOfficeCity,
        registeredOfficeCountry: this.registeredOfficeCountry,
        registeredOfficePostalCode: this.registeredOfficePostalCode,
      };
      this.$refs.editModal.init(this.questionnaire, this.fundInfoList, registeredForm, this.hasNomineeDirector);
    },
  },
};
</script>
<style lang="scss" scoped>
.desp {
  .member-type {
    color: #011a45;
    font-size: 14px;
    font-weight: bold;
    padding: 3px 0 0 15px;
    margin-bottom: 5px;
  }

  .position-held {
    font-size: 16px;
    color: #0060ff;
    font-weight: bolder;
    padding: 3px 15px;
    background: #eceff9;
    margin-bottom: 15px;
    border-radius: 4px;
  }

  .base-info {
    padding: 20px 30px;
    background-color: #eceff9;
    border-radius: 4px;
  }

  .checkboxCustom {
    ::v-deep .el-checkbox__label {
      /* 这两个在技术上是一样的, 为了兼容了浏览器两个都加上 */
      overflow-wrap: break-word !important;
      word-wrap: break-word !important;

      -ms-word-break: break-all !important;
      /* 这个的使用在web-kit中有些危险，他可能会阶段所有东西 */
      word-break: break-all !important;
      /* Instead use this non-standard one: */
      word-break: break-word !important;

      /* 如果浏览器支持的话增加一个连接符(Blink不支持) */
      hyphens: auto !important;

      display: inline-grid;
      white-space: pre-line;
      line-height: 20px;
    }
  }
}
</style>
