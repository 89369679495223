var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-container"
  }, [_c('div', [_c('el-form', {
    staticClass: "el-form-filter",
    attrs: {
      "inline": true,
      "model": _vm.dataForm
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.getDataList(1);
      }
    }
  }, [_c('el-form-item', [_c('el-input', {
    staticClass: "input-with-select-lg",
    staticStyle: {
      "width": "500px"
    },
    attrs: {
      "placeholder": _vm.kwPlaceHolder,
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.keyword,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "keyword", $$v);
      },
      expression: "dataForm.keyword"
    }
  }, [_c('el-select', {
    attrs: {
      "slot": "prepend",
      "placeholder": "please select"
    },
    slot: "prepend",
    model: {
      value: _vm.dataForm.kwType,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "kwType", $$v);
      },
      expression: "dataForm.kwType"
    }
  }, _vm._l(_vm.kwTypes, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1), _c('el-form-item', [_c('el-select', {
    attrs: {
      "multiple": "",
      "clearable": "",
      "placeholder": "Country of Company"
    },
    model: {
      value: _vm.dataForm.country,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "country", $$v);
      },
      expression: "dataForm.country"
    }
  }, _vm._l(_vm.countryList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', [_c('el-select', {
    attrs: {
      "placeholder": "select coordinator",
      "multiple": "",
      "clearable": ""
    },
    model: {
      value: _vm.dataForm.coordinatorIds,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "coordinatorIds", $$v);
      },
      expression: "dataForm.coordinatorIds"
    }
  }, [_c('el-option', {
    attrs: {
      "label": "unassigned",
      "value": -1
    }
  }), _vm._l(_vm.userList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.nickname || item.username,
        "value": item.id
      }
    });
  })], 2)], 1), _c('el-form-item', [_c('el-select', {
    attrs: {
      "placeholder": "select reviewer",
      "multiple": "",
      "clearable": ""
    },
    model: {
      value: _vm.dataForm.auditorIds,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "auditorIds", $$v);
      },
      expression: "dataForm.auditorIds"
    }
  }, [_c('el-option', {
    attrs: {
      "label": "unassigned",
      "value": -1
    }
  }), _vm._l(_vm.userList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.nickname || item.username,
        "value": item.id
      }
    });
  })], 2)], 1), _c('el-form-item', [_c('el-select-all', {
    attrs: {
      "optionList": _vm.applymentStatusScreenList,
      "placeholder": "Status"
    },
    on: {
      "elSelectAllChange": function (list) {
        _vm.dataForm.applymentStatus = list;
      }
    }
  })], 1), _c('el-form-item', [_c('el-select', {
    attrs: {
      "clearable": "",
      "placeholder": "Entity Type"
    },
    model: {
      value: _vm.dataForm.businessGenre,
      callback: function ($$v) {
        _vm.$set(_vm.dataForm, "businessGenre", $$v);
      },
      expression: "dataForm.businessGenre"
    }
  }, _vm._l(_vm.entityTypes, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', [_c('el-button', {
    attrs: {
      "icon": "el-icon-search",
      "type": "primary",
      "plain": ""
    },
    on: {
      "click": function ($event) {
        return _vm.getDataList(1);
      }
    }
  }, [_vm._v("Search")])], 1)], 1)], 1), _c('div', {
    staticClass: "mt20"
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.dataListLoading,
      expression: "dataListLoading"
    }],
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "max-height": "600",
      "data": _vm.dataList,
      "default-sort": {
        prop: 'inquiryTime',
        order: 'descending'
      }
    },
    on: {
      "sort-change": _vm.tableSortChange
    }
  }, [_c('el-table-column', {
    attrs: {
      "fixed": "left",
      "prop": "applymentNo",
      "align": "center",
      "header-align": "center",
      "label": "Quotation No.",
      "min-width": "160"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "inquiryTime",
      "header-align": "center",
      "align": "center",
      "label": "First Request Time",
      "width": "260",
      "sortable": "custom"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "latestInquiryTime",
      "header-align": "center",
      "align": "center",
      "label": "Latest Remind Time",
      "width": "260"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "businessGenre",
      "header-align": "center",
      "align": "center",
      "label": "Entity Type",
      "width": "180",
      "formatter": _vm.entityTypeFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "applymentStatus",
      "header-align": "center",
      "align": "center",
      "label": "Status",
      "width": "200",
      "formatter": _vm.statusFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "applicantName",
      "header-align": "center",
      "align": "center",
      "label": "Applicant Name",
      "width": "160"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "applicantEmail",
      "header-align": "center",
      "align": "center",
      "label": "Applicant Email",
      "width": "200"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "country",
      "header-align": "center",
      "align": "center",
      "label": "Country of Company",
      "width": "180"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "proposedCompanyName1",
      "header-align": "center",
      "align": "center",
      "label": "Proposed Company Name",
      "width": "400",
      "formatter": _vm.companyNameFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Fund(s) Name",
      "width": "400"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return _vm._l(scope.row.fundInfoList, function (item, index) {
          return _c('span', {
            key: `fund${item.id}`
          }, [_vm._v(_vm._s(index != 0 ? "、" : "") + " " + _vm._s(item.fundName))]);
        });
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "coordinator",
      "header-align": "center",
      "align": "center",
      "label": "Coordinator",
      "width": "200",
      "formatter": _vm.auditorFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "auditor",
      "header-align": "center",
      "align": "center",
      "label": "Reviewer",
      "width": "200",
      "formatter": _vm.auditorFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "fixed": "right",
      "header-align": "center",
      "align": "center",
      "width": "180",
      "label": "Action"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function ($event) {
              return _vm.detailHandler(scope.row.id);
            }
          }
        }, [_vm._v("Detail")]), _vm.isAuth('quotation:forfeit') && !scope.row.hasPaid && scope.row.applymentStatus != 'FORFEITED' ? _c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function ($event) {
              return _vm.statusUpdateHandle(scope.row, 'Forfeit');
            }
          }
        }, [_vm._v(" Forfeit ")]) : _vm._e(), _vm.isAuth('quotation:reActive') && !scope.row.hasPaid && scope.row.applymentStatus == 'FORFEITED' ? _c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function ($event) {
              return _vm.statusUpdateHandle(scope.row, 'Re-active');
            }
          }
        }, [_vm._v(" Re-active ")]) : _vm._e()];
      }
    }])
  })], 1), _c('el-pagination', {
    attrs: {
      "current-page": _vm.current,
      "page-sizes": [10, 20, 50, 100],
      "page-size": _vm.size,
      "total": _vm.total,
      "layout": "total, sizes, prev, pager, next, jumper"
    },
    on: {
      "size-change": _vm.sizeChangeHandle,
      "current-change": _vm.currentChangeHandle
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }