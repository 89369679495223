<template>
  <div class="page-container">
    <div class="flex-row jcsb">
      <div>
        <el-button v-if="!noContent" type="primary" @click="addNew()">Add Service</el-button>
        <!-- 导出 excel -->
        <el-button
          type="primary"
          plain
          size="small"
          icon="el-icon-download"
          v-if="isAuth('sys:module:download')"
          @click="handleExportExcel"
          :disabled="dataListSelections.length <= 0"
        >
          Export Excel
        </el-button>
      </div>
      <div>
        <el-form class="el-form-filter" :inline="true" :model="dataForm" @keyup.enter.native="getDataList(1)">
          <el-form-item>
            <el-select @change="handleCountryChange" v-model="dataForm.country" clearable placeholder="Country">
              <el-option v-for="item in countryList" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select @change="handleBusinessTypeChange" v-model="dataForm.businessType" clearable placeholder="Business Type">
              <el-option v-for="item in businessTypes" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="dataForm.keyword" placeholder="Name Or Name(CN)"></el-input>
          </el-form-item>
          <el-button icon="el-icon-search" type="primary" plain @click="getDataList(1)">Search</el-button>
        </el-form>
      </div>
    </div>
    <div class="mt20">
      <el-table :data="dataList" v-loading="dataListLoading" @selection-change="selectionChangeHandle" style="width: 100%">
        <el-table-column type="selection" header-align="center" align="center" width="50" fixed="left"></el-table-column>
        <el-table-column prop="country" header-align="center" align="center" label="Country" min-width="140"></el-table-column>
        <el-table-column
          prop="businessTypeList"
          header-align="center"
          align="center"
          label="Business Type"
          :formatter="businessTypeListFormat"
          min-width="200"
        ></el-table-column>
        <el-table-column prop="name" header-align="center" align="center" label="Name" min-width="250"></el-table-column>
        <el-table-column prop="nameCn" header-align="center" align="center" label="Name(CN)" min-width="250"></el-table-column>
        <el-table-column prop="price" header-align="center" align="center" label="Price" min-width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.price">S${{ scope.row.price }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="originalPrice" header-align="center" align="center" label="Original Price" min-width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.originalPrice">S${{ scope.row.originalPrice }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" header-align="center" align="center" label="Update Time" min-width="160"></el-table-column>
        <el-table-column header-align="center" align="center" label="Action" min-width="150" fixed="right">
          <template slot-scope="scope">
            <el-button @click="deleteHandle(scope.row.id)" type="text" class="fwb" v-if="isAuth('sys:module:delete') && !scope.row.moduleCode">
              Delete
            </el-button>
            <el-button @click="updateHandle(scope.row.id)" type="text" class="fwb">Edit</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalCount"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { open } from "@/utils";
import axiosInstance from "@/utils/http";
import { dateFormat } from "@/utils";
import paginationDelete from "@/mixins/paginationDelete.js";
import { mapState } from "vuex";
export default {
  mixins: [paginationDelete],
  data() {
    return {
      dataList: [],
      current: 1,
      pageSize: 6,
      totalCount: 0,
      dataListLoading: false,
      dataListSelections: [],
      dataForm: {
        country: "",
        businessType: "",
        keyword: "",
      },
    };
  },
  activated() {
    this.getDataList();
  },
  computed: {
    ...mapState("constant", ["countryList", "businessTypes"]),
    noContent() {
      return this.totalCount === 0;
    },
  },
  methods: {
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.current = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    /* 格式化 */
    localDateFormat(datetime) {
      if (datetime) {
        return dateFormat(new Date(datetime.replace(/-/g, "/")), "yyyy-MM-dd");
      }
      return "";
    },
    businessTypeListFormat(row, column, cellValue) {
      let titleArr = [];
      this.businessTypes.forEach(item => {
        cellValue.forEach(child => {
          if (item.value === child) {
            titleArr.push(item?.title);
          }
        });
      });
      return titleArr.toString();
    },
    /* 格式化 */
    //国家状态选择
    handleCountryChange(current) {
      this.dataForm.country = current;
      this.getDataList(1);
    },
    //businessType选择
    handleBusinessTypeChange(current) {
      this.dataForm.businessType = current;
      this.getDataList(1);
    },
    //添加新套餐
    addNew() {
      this.$router.push("/admin/sys/pkg/module/add");
    },
    //数据请求
    async getDataList(current) {
      this.dataListLoading = true;
      if (current) {
        this.current = current;
      }
      const params = {
        current: this.current,
        size: this.pageSize,
        ...this.dataForm,
        "orders[0].asc": false,
        "orders[0].column": "id",
      };
      const { code, data } = await this.$http.get("/admin/moduleManage/paging", {
        params,
      });
      if (code === 200) {
        this.dataList = data.records;
        this.totalCount = data.total;
      } else {
        this.dataList = [];
        this.totalCount = 0;
      }
      this.dataListLoading = false;
    },
    updateHandle(id) {
      this.$router.push(`/sys/pkg/module/${id}/edit`);
    },
    deleteHandle(id) {
      this.$confirm(`Delete this Service. Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(async () => {
        const { code, msg } = await this.$http.post(`/admin/moduleManage/${id}/delete`);
        if (code === 200) {
          this.$message({
            message: "success",
            type: "success",
            duration: 1500,
            onClose: () => {
              // mixins
              this.singleDataDelete();
              this.getDataList();
            },
          });
        } else {
          this.$message.error(msg);
        }
      });
    },
    // 导出excel
    async handleExportExcel() {
      let idArr = this.dataListSelections.map(item => item.id);
      let ids = idArr.toString();
      open(`${axiosInstance.defaults.baseURL}/admin/moduleXlsxManage/download?moduleIds=${ids}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.plan-modules-wrapper {
  .relative {
    border: none;
    background: #f9fafd;
    .module-item {
      padding: 18px 20px 18px;
      display: flex;
      align-items: center;
      overflow: hidden;
      flex-wrap: nowrap;
      border: 2px dashed #e9ecf1;
      border-bottom: none;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      .module-desc {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        overflow: hidden;
        font-size: 16px;

        .item-label {
          display: inline-block;
          width: 60px;
          color: #7987a0;
          font-size: 14px;
          margin-right: 10px;
        }
        .item-wrap {
          line-height: 38px;
          margin: 0px;
        }
        .item-content {
          font-size: 14px;
          font-weight: bold;
          color: #011a45;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-all;
          display: inline-block;
          width: calc(100% - 70px);
          vertical-align: middle;
        }
        .date {
          margin: 24px 0 10px;
          font-size: 14px;
        }
      }
    }
    .el-card-actions {
      border: 2px dashed #e9ecf1;
      border-top: 2px solid #e9ecf1;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      background: transparent;
    }

    .el-card-actions li:not(:last-child) {
      border-right: 2px solid #e9ecf1;
    }
  }

  .relative:hover {
    background: #e8f0ff;
    .module-item {
      border-color: #e8f0ff;
    }
    .el-card-actions {
      border-color: #e8f0ff;
      border-top: 2px solid #d8e3ff;
    }
    .el-card-actions li:not(:last-child) {
      border-right: 2px solid #d8e3ff;
    }
  }
}
</style>
