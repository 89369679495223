<template>
  <div class="page-container">
    <div>
      <el-form class="el-form-filter" :inline="true" :model="dataForm" @keyup.enter.native="getDataList(1)">
        <el-form-item>
          <el-input style="width: 500px" v-model="dataForm.keyword" :placeholder="kwPlaceHolder" class="input-with-select-lg" :oninput="isEn">
            <el-select v-model="dataForm.kwType" slot="prepend" placeholder="please select">
              <el-option v-for="item in kwTypes" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.country" multiple clearable placeholder="Country of Company">
            <el-option v-for="item in countryList" :key="item.value" :label="item.title" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.coordinatorIds" placeholder="select coordinator" multiple clearable>
            <el-option label="unassigned" :value="-1"></el-option>
            <el-option v-for="item in userList" :key="item.value" :label="item.nickname || item.username" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-select v-model="dataForm.checkerIds" placeholder="select checker" multiple clearable>
            <el-option label="unassigned" :value="-1"></el-option>
            <el-option v-for="item in userList" :key="item.value" :label="item.nickname || item.username" :value="item.id"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-select v-model="dataForm.auditorIds" placeholder="select reviewer" multiple clearable>
            <el-option label="unassigned" :value="-1"></el-option>
            <el-option v-for="item in userList" :key="item.value" :label="item.nickname || item.username" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-date-picker
            value-format="timestamp"
            v-model="tradeTimeRange"
            type="daterange"
            range-separator="~"
            start-placeholder="Start Time"
            end-placeholder="End Time"
            style="width: 384px"
          ></el-date-picker>
        </el-form-item> -->
        <el-form-item>
          <el-select-all
            :optionList="applymentStatusScreenList"
            placeholder="Status"
            @elSelectAllChange="
              list => {
                dataForm.applymentStatus = list;
              }
            "
          ></el-select-all>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.businessGenre" clearable placeholder="Entity Type">
            <el-option v-for="item in entityTypes" :key="item.value" :label="item.title" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" plain @click="getDataList(1)">Search</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 列表 -->
    <div class="mt20">
      <el-table
        max-height="600"
        :data="dataList"
        v-loading="dataListLoading"
        @sort-change="tableSortChange"
        :default-sort="{ prop: 'inquiryTime', order: 'descending' }"
        style="width: 100%"
      >
        <el-table-column fixed="left" prop="applymentNo" align="center" header-align="center" label="Quotation No." min-width="160" />
        <el-table-column prop="inquiryTime" header-align="center" align="center" label="First Request Time" width="260" sortable="custom" />
        <el-table-column prop="latestInquiryTime" header-align="center" align="center" label="Latest Remind Time" width="260" />
        <el-table-column prop="businessGenre" header-align="center" align="center" label="Entity Type" width="180" :formatter="entityTypeFormat" />
        <el-table-column prop="applymentStatus" header-align="center" align="center" label="Status" width="200" :formatter="statusFormat" />
        <el-table-column prop="applicantName" header-align="center" align="center" label="Applicant Name" width="160" />
        <el-table-column prop="applicantEmail" header-align="center" align="center" label="Applicant Email" width="200" />
        <el-table-column prop="country" header-align="center" align="center" label="Country of Company" width="180" />
        <el-table-column
          prop="proposedCompanyName1"
          header-align="center"
          align="center"
          label="Proposed Company Name"
          width="400"
          :formatter="companyNameFormat"
        />
        <el-table-column header-align="center" align="center" label="Fund(s) Name" width="400">
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.fundInfoList" :key="`fund${item.id}`">{{ index != 0 ? "、" : "" }} {{ item.fundName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="coordinator" header-align="center" align="center" label="Coordinator" width="200" :formatter="auditorFormat" />
        <el-table-column prop="auditor" header-align="center" align="center" label="Reviewer" width="200" :formatter="auditorFormat" />
        <el-table-column fixed="right" header-align="center" align="center" width="180" label="Action">
          <template slot-scope="scope">
            <el-button @click="detailHandler(scope.row.id)" type="text" class="fwb">Detail</el-button>
            <!-- 禁用 -->
            <el-button
              v-if="isAuth('quotation:forfeit') && !scope.row.hasPaid && scope.row.applymentStatus != 'FORFEITED'"
              type="text"
              class="fwb"
              @click="statusUpdateHandle(scope.row, 'Forfeit')"
            >
              Forfeit
            </el-button>
            <!-- 启用 -->
            <el-button
              v-if="isAuth('quotation:reActive') && !scope.row.hasPaid && scope.row.applymentStatus == 'FORFEITED'"
              type="text"
              class="fwb"
              @click="statusUpdateHandle(scope.row, 'Re-active')"
            >
              Re-active
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ElSelectAll from "@/components/ElSelectAll";
export default {
  components: {
    ElSelectAll,
  },
  data() {
    return {
      dataList: [],
      current: 1,
      size: 10,
      total: 0,
      dataListLoading: false,
      kwTypes: [
        {
          title: "Quotation No.",
          value: "applymentNo",
          placeholder: "Quotation No.",
        },
        {
          title: "Applicant",
          value: "applicant",
          placeholder: "Applicant Name Or Applicant Email",
        },
        {
          title: "Company Name",
          value: "companyName",
          placeholder: "Company Name",
        },
        {
          title: "Fund Name",
          value: "fundsName",
          placeholder: "Fund Name",
        },
      ],
      tradeTimeRange: null,
      dataForm: {
        kwType: "applymentNo",
        keyword: "",
        applymentNo: "",
        applicant: "",
        companyName: "",
        fundsName: "",
        coordinatorIds: "",
        checkerIds: "",
        auditorIds: "",
        startTime: null,
        endTime: null,
        applymentStatus: "",
        businessGenre: "",
        "orders[0].asc": false,
        "orders[0].column": "t1.inquiry_time",
        businessTypes: ["COMPREHENSIVE_CUSTOMIZED", "FUND_ADMIN_CUSTOMIZED"],
      },
      userList: [],
    };
  },
  activated() {
    this.getDataList();
    this.getSysUserList();
  },
  computed: {
    ...mapState("constant", ["applymentStatusList", "countryList", "entityTypes"]),
    kwPlaceHolder() {
      return this.kwTypes.find(i => i.value === this.dataForm.kwType).placeholder;
    },
    applymentStatusScreenList() {
      return this.applymentStatusList.filter(item => item.value != "DRAFT");
    },
  },
  watch: {
    tradeTimeRange(newValue, oldValue) {
      if (newValue) {
        this.dataForm.startTime = newValue[0];
        this.dataForm.endTime = newValue[1];
      } else {
        this.dataForm.startTime = undefined;
        this.dataForm.endTime = undefined;
      }
    },
  },
  methods: {
    // table - 排序
    tableSortChange(column) {
      if (column.prop == "inquiryTime") {
        this.dataForm["orders[0].column"] = "t1.inquiry_time";
        if (column.order === "descending") {
          this.dataForm["orders[0].asc"] = false; //倒序
          this.getDataList();
        } else if (column.order === "ascending") {
          this.dataForm["orders[0].asc"] = true; //正序
          this.getDataList();
        }
      } else {
        this.dataForm["orders[0].column"] = "t1.commit_time";
        if (column.order === "descending") {
          this.dataForm["orders[0].asc"] = false; //倒序
          this.getDataList();
        } else if (column.order === "ascending") {
          this.dataForm["orders[0].asc"] = true; //正序
          this.getDataList();
        }
      }
    },
    // 每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.current = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList();
    },
    /* 格式化 */
    companyNameFormat(row, column, cellValue) {
      if (row.companyName) {
        const { companyName } = row;
        return (
          <div>
            <div>{companyName}</div>
          </div>
        );
      } else if (row.proposedCompanyName) {
        const { proposedCompanyName } = row;
        return (
          <div>
            <div>{proposedCompanyName}</div>
          </div>
        );
      } else {
        const { proposedCompanyName1, proposedCompanyName2, proposedCompanyName3 } = row;
        return (
          <div>
            <div>{proposedCompanyName1}</div>
            <div>{proposedCompanyName2}</div>
            <div>{proposedCompanyName3}</div>
          </div>
        );
      }
    },
    entityTypeFormat(row, column, cellValue) {
      const one = this.entityTypes.find(i => i.value === cellValue);
      return one?.title;
    },
    statusFormat(row, column, cellValue) {
      const one = this.applymentStatusList.find(i => i.value === cellValue);
      return one?.title;
    },
    auditorFormat(row, column, cellValue) {
      return cellValue && (cellValue.nickname || cellValue.username);
    },
    /* 格式化 */
    //用户列表
    async getSysUserList() {
      const { data } = await this.$http.get(`/admin/sysUser/getList`);
      this.userList = data;
    },
    //申请单状态
    handleApplymentStatusChange() {
      this.getDataList(1);
    },
    handleCountryChange() {
      this.getDataList(1);
    },
    //详情
    detailHandler(id) {
      this.$router.push(`/quotation/${id}`);
    },
    // 获取数据列表
    async getDataList(current) {
      this.dataListLoading = true;
      if (current) {
        this.current = current;
      }
      const { code, data } = await this.$http.get("/admin/companyApplymentManage/paging", {
        params: {
          current: this.current,
          size: this.size,
          // "orders[0].asc": false,
          // "orders[0].column": "id",
          ...this.dataForm,
          [this.dataForm.kwType]: this.dataForm.keyword,
        },
      });
      if (code === 200) {
        this.dataList = data.records;
        this.total = data.total;
      } else {
        this.dataList = [];
        this.total = 0;
      }
      this.dataListLoading = false;
    },
    // 禁用、启用
    async statusUpdateHandle(row, tipe) {
      await this.$confirm(`${tipe} This Quotation. Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      });
      this.$http
        .post(`/admin/companyApplymentStatusManage/${tipe == "Forfeit" ? "forfeit" : "reActive"}`, {
          applymentId: row.id,
        })
        .then(({ code, msg }) => {
          if (code === 200) {
            this.$message.success("success");
            this.getDataList();
          } else {
            this.$message.error(msg);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.company-name-tag {
  display: inline-flex;
  flex-direction: column;
}
</style>
