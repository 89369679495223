<template>
  <div class="page-container">
    <div class="official-plan-list">
      <el-form :inline="true" @keyup.enter.native="getDataList(1)" class="flex-row jcsb">
        <div>
          <el-form-item>
            <el-button type="primary" @click="addNew()">New Plan</el-button>
          </el-form-item>
          <el-form-item>
            <el-button v-if="isAuth('sys:plan:config')" @click="setConfig">Config</el-button>
          </el-form-item>
        </div>
        <div>
          <el-form-item>
            <el-select @change="handleCountryChange" v-model="dataForm.country" clearable placeholder="Country">
              <el-option v-for="item in countryList" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select @change="handleBusinessTypeChange" v-model="dataForm.businessType" clearable placeholder="Business Type">
              <el-option v-for="item in businessTypes" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="dataForm.planName" placeholder="Plan Name"></el-input>
          </el-form-item>
          <el-button icon="el-icon-search" type="primary" plain @click="getDataList(1)">Search</el-button>
        </div>
      </el-form>
      <el-card shadow="nerver">
        <el-table :data="dataList" v-loading="dataListLoading" style="width: 100%">
          <el-table-column prop="name" header-align="center" align="center" width="250" label="Name"></el-table-column>
          <el-table-column
            prop="businessType"
            header-align="center"
            align="center"
            width="250"
            label="Business Type"
            :formatter="businessTypeFormat"
          ></el-table-column>
          <el-table-column prop="country" header-align="center" align="center" width="250" label="Country"></el-table-column>
          <el-table-column prop="summary" header-align="center" align="center" width="250" label="Summary"></el-table-column>
          <!-- <el-table-column prop="priceSg" header-align="center" align="center" width="250" label="Price(SGD)"></el-table-column> -->
          <!-- <el-table-column prop="priceCn" header-align="center" align="center" width="250" label="Price(CNY)"></el-table-column> -->
          <el-table-column prop="publishTime" header-align="center" align="center" width="250" label="Publish Time"></el-table-column>
          <el-table-column prop="status" header-align="center" align="center" width="150" label="Status" :formatter="statusFormat"></el-table-column>
          <el-table-column fixed="right" header-align="center" align="center" min-width="200" label="Action">
            <template slot-scope="scope">
              <el-button type="text" class="fwb" @click="updateHandle(scope.row.id)">Edit</el-button>
              <el-button type="text" class="fwb" @click="deleteHandle(scope.row.id)">Delete</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="current"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalCount"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </el-card>
    </div>
    <!-- Config 弹框 -->
    <config-modal ref="configModal"></config-modal>
  </div>
</template>

<script>
import ConfigModal from "./modal/config";
import paginationDelete from "@/mixins/paginationDelete.js";
import { mapState } from "vuex";
export default {
  mixins: [paginationDelete],
  components: {
    ConfigModal,
  },
  data() {
    return {
      dataList: [],
      current: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      dataListSelections: [],
      dataForm: {
        country: "",
        businessType: "",
        planName: "",
      },
    };
  },
  computed: {
    ...mapState("constant", ["businessTypes", "countryList"]),
  },
  activated() {
    this.getDataList();
  },
  methods: {
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.current = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList();
    },
    /* 格式化 */
    businessTypeFormat(row, column, cellValue) {
      const one = this.businessTypes.find(i => i.value === cellValue);
      return one?.title;
    },
    statusFormat(row, column, cellValue) {
      if (cellValue === 0) {
        return (
          <el-tag size="samll" type="danger">
            Disable
          </el-tag>
        );
      } else {
        return <el-tag size="samll">Enable</el-tag>;
      }
    },
    /* 格式化 */
    //国家状态选择
    handleCountryChange(current) {
      this.dataForm.country = current;
      this.getDataList(1);
    },
    //businessType选择
    handleBusinessTypeChange(current) {
      this.dataForm.businessType = current;
      this.getDataList(1);
    },
    //添加新套餐
    addNew() {
      this.$router.push("/admin/sys/pkg/plan/add");
    },
    //数据请求
    async getDataList(current) {
      this.dataListLoading = true;
      if (current) {
        this.current = current;
      }
      const params = {
        current: this.current,
        size: this.pageSize,
        ...this.dataForm,
        "orders[0].asc": false,
        "orders[0].column": "id",
      };
      const { code, data } = await this.$http.get("/admin/planManage/paging", {
        params,
      });
      if (code === 200) {
        this.dataList = data.records;
        this.totalCount = data.total;
      } else {
        this.dataList = [];
        this.totalCount = 0;
      }
      this.dataListLoading = false;
    },
    updateHandle(id) {
      this.$router.push(`/sys/pkg/plan/${id}/edit`);
    },
    deleteHandle(id) {
      this.$confirm(`Delete this Plan. Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(async () => {
        const { code, msg } = await this.$http.post(`/admin/planManage/${id}/delete`);
        if (code === 200) {
          this.$message({
            message: "success",
            type: "success",
            duration: 1500,
            onClose: () => {
              // mixins
              this.singleDataDelete();
              this.getDataList();
            },
          });
        } else {
          this.$message.error(msg);
        }
      });
    },
    setConfig() {
      this.$refs.configModal.init();
    },
  },
};
</script>
