<template>
  <el-dialog width="1100px" title="Edit Extra Info" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" autocomplete="off" label-position="top">
      <el-form-item label="Registered Office" prop="registeredOfficeType">
        <el-radio-group v-model="dataForm.registeredOfficeType">
          <div v-for="item in registerOfficeTypeList" :key="item.value">
            <el-radio class="pt15 pb15" :label="item.value">
              <span>{{ item.title }}</span>
              <div class="mt15 ml24" v-if="item.value === 1 && dataForm.registeredOfficeType === 1">
                <el-radio-group v-model="dataForm.registeredOfficeId">
                  <el-radio v-for="office in nativeRegisteredAddress" :key="office.id" :label="office.id">{{ formatNativeAddress(office) }}</el-radio>
                </el-radio-group>
              </div>
              <div class="mt10 ml24" v-if="item.value === 2 && dataForm.registeredOfficeType === 2">
                <el-row :gutter="20">
                  <el-col :span="9">
                    <el-form-item label="Address">
                      <el-input v-model="dataForm.registeredOfficeAddress" :oninput="isEn"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="5">
                    <el-form-item label="City">
                      <el-input v-model="dataForm.registeredOfficeCity" :oninput="isEn"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="5">
                    <el-form-item label="Country">
                      <!-- <el-input v-model="dataForm.registeredOfficeCountry" :oninput="isEn"></el-input> -->
                      <el-select v-model="dataForm.registeredOfficeCountry" placeholder="Select Country" filterable>
                        <el-option v-for="item in countryList" :key="item.value" :label="item.title" :value="item.value"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="5">
                    <el-form-item label="Code">
                      <el-input v-model="dataForm.registeredOfficePostalCode" :oninput="isEn"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </el-radio>
          </div>
        </el-radio-group>
      </el-form-item>
      <el-row>
        <el-col :span="24">
          <el-form-item label="Working hours" prop="workingHours">
            <el-select style="width: 100%" v-model="dataForm.workingHours" clearable placeholder="working hours">
              <el-option v-for="item in workingHoursOptions" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="Constitution" prop="isCustomizedConstitution">
            <el-select style="width: 100%" v-model="dataForm.isCustomizedConstitution" clearable placeholder="Constitution">
              <el-option v-for="item in customizedConstitutionOptions" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item
        label="Is the company's business special and needs to obtain approval(s) from specific government authorities?"
        prop="hasObtainedApproval"
      >
        <el-radio-group v-model="dataForm.hasObtainedApproval">
          <el-radio v-for="item in checkboxOptions" :key="item.value" :label="item.value">{{ item.title }}</el-radio>
        </el-radio-group>
        <el-checkbox-group v-if="dataForm.hasObtainedApproval === 1" v-model="dataForm.approvalAuthorities">
          <el-checkbox v-for="item in authoritiesOptions" :key="item.value" :label="item.title" :value="item.value"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {
    nativeRegisteredAddress: {
      type: Array,
    },
    registerOfficeTypeList: {
      type: Array,
    },
    checkboxOptions: {
      type: Array,
    },
    workingHoursOptions: {
      type: Array,
    },
    customizedConstitutionOptions: {
      type: Array,
    },
    authoritiesOptions: {
      type: Array,
    },
    businessType: {
      type: String,
    },
  },
  data() {
    return {
      visible: false,
      dataForm: {
        id: 0,
        registeredOfficeType: 1,
        registeredOfficeId: "",
        registeredOfficeAddress: "",
        registeredOfficeCity: "",
        registeredOfficeCountry: "",
        registeredOfficePostalCode: "",
        workingHours: "",
        isCustomizedConstitution: null,
        hasObtainedApproval: 0,
        approvalAuthorities: [],
      },
      dataRule: {},
      submitControl: true,
    };
  },
  computed: {
    ...mapState("constant", ["countryList", "businessStepObj"]),
    nativeCountryList() {
      return this.countryList.filter(i => i.native);
    },
  },
  methods: {
    ...mapActions("applyment", ["doSaveRevising"]),
    async init(data) {
      this.submitControl = true;
      this.visible = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      const copy = JSON.parse(JSON.stringify(data));
      if (!copy.approvalAuthorities) {
        copy.approvalAuthorities = [];
      }
      this.dataForm = copy;
    },
    formatNativeAddress(office) {
      return office.address + " ," + office.city + " ," + office.country + " ," + office.postalCode;
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          this.doSaveRevising({
            ...this.dataForm,
            procedure: {
              step: this.businessStepObj[this.businessType]["companyDetails"],
            },
          })
            .then(() => {
              this.$message({
                message: "success",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.submitControl = true;
                },
              });
            })
            .catch(msg => {
              this.$message.error(msg);
              this.submitControl = true;
            });
        }
      });
    },
  },
};
</script>
