<template>
  <div class="desp mt40">
    <div class="desp-header">
      <div class="label">Extra Information</div>
      <div class="opts">
        <el-button
          v-if="
            (detail.applymentStatus === 'REVISING' ||
              detail.applymentStatus === 'CORRECTED' ||
              detail.applymentStatus === 'ILLEGAL' ||
              detail.applymentStatus === 'AUDIT_FAIL' ||
              detail.applymentStatus === 'REFUND_APPLY_DENY' ||
              detail.applymentStatus === 'REFUND_APPLY_REJECT' ||
              detail.applymentStatus === 'ACRA_REJECTED') &&
            isApplymentAuth('company:coordinate')
          "
          @click="openEdit()"
          ref=""
        >
          Edit
        </el-button>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title">Registered Office</div>
      <div class="desp-item">
        <div class="desp-item-self no-event">
          <el-radio-group :value="detail.registeredOfficeType">
            <div v-for="item in registerOfficeTypeList" :key="item.value">
              <el-radio class="pt15 pb15" :label="item.value">
                <span>{{ item.title }}</span>
                <div class="mt15 ml24" v-if="item.value === 1 && detail.registeredOfficeType === 1">
                  <el-radio-group :value="detail.registeredOfficeId">
                    <el-radio v-for="office in nativeRegisteredAddress" :key="office.id" :label="office.id">{{ formatNativeAddress(office) }}</el-radio>
                  </el-radio-group>
                </div>
                <div class="mt10 ml24" v-if="item.value === 2 && detail.registeredOfficeType === 2">
                  {{ formatCustomAddress(detail) }}
                </div>
              </el-radio>
            </div>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title">Working hours</div>
      <div class="desp-item">
        <div class="desp-item-self no-event">
          <el-row>
            <el-col :span="16">
              <el-select style="width: 100%" :value="detail.workingHours">
                <el-option v-for="item in workingHoursOptions" :key="item.value" :label="item.title" :value="item.value"></el-option>
              </el-select>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title">Constitution</div>
      <div class="desp-item">
        <div class="desp-item-self no-event">
          <el-row>
            <el-col :span="16">
              <el-select style="width: 100%" :value="detail.isCustomizedConstitution">
                <el-option v-for="item in customizedConstitutionOptions" :key="item.value" :label="item.title" :value="item.value"></el-option>
              </el-select>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="desp-items-group">
      <div class="desp-items-group-title">Is the company's business special and needs to obtain approval(s) from specific government authorities?</div>
      <div class="desp-item">
        <div class="desp-item-self no-event">
          <el-radio-group v-model="detail.hasObtainedApproval">
            <el-radio v-for="item in checkboxOptions" :key="item.value" :label="item.value">{{ item.title }}</el-radio>
          </el-radio-group>
          <el-checkbox-group v-if="detail.hasObtainedApproval === 1" :value="detail.approvalAuthorities || []">
            <el-checkbox v-for="item in authoritiesOptions" :key="item.value" :label="item.title" :value="item.value"></el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <edit-modal
      v-if="editVisible"
      ref="editModal"
      :checkboxOptions="checkboxOptions"
      :workingHoursOptions="workingHoursOptions"
      :customizedConstitutionOptions="customizedConstitutionOptions"
      :authoritiesOptions="authoritiesOptions"
      :nativeRegisteredAddress="nativeRegisteredAddress"
      :registerOfficeTypeList="registerOfficeTypeList"
      :businessType="detail.businessType"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import EditModal from "./modal/Edit";
export default {
  components: {
    EditModal,
  },
  props: {
    detail: {
      type: Object,
      default: function () {
        return {
          registeredOfficeType: 1,
          registeredOfficeId: "",
          registeredOfficeAddress: "",
          registeredOfficeCity: "",
          registeredOfficeCountry: "",
          registeredOfficePostalCode: "",
          workingHours: "",
          hasObtainedApproval: 0,
          approvalAuthorities: [],
          businessType: "",
          businessGenre: "",
        };
      },
    },
    nativeRegisteredAddress: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      dataListLoading: false,
      editVisible: false,
      checkboxOptions: [
        {
          title: "Yes",
          value: 1,
        },
        {
          title: "Uncertain, need EStar's assistance",
          value: 0,
        },
      ],
      registerOfficeTypeList: [
        {
          title: `Consent to use EStar Business Services Pte. Ltd. ("EStar")'s registration address`,
          value: 1,
        },
        {
          title: "Use other registration address",
          value: 2,
        },
      ],
    };
  },
  computed: {
    ...mapState("constant", ["workingHoursOptions", "customizedConstitutionOptions", "authoritiesOptions"]),
  },
  methods: {
    formatNativeAddress(office) {
      return office.address + " ," + office.city + " ," + office.country + " ," + office.postalCode;
    },
    formatCustomAddress(office) {
      return (
        office.registeredOfficeAddress +
        " ," +
        office.registeredOfficeCity +
        " ," +
        office.registeredOfficeCountry +
        " ," +
        office.registeredOfficePostalCode
      );
    },
    async openEdit() {
      this.editVisible = true;
      await this.$nextTick();
      this.$refs.editModal.init(this.detail);
    },
  },
};
</script>
