var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp mt40"
  }, [_c('div', {
    staticClass: "desp-header"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Extra Information")]), _c('div', {
    staticClass: "opts"
  }, [(_vm.detail.applymentStatus === 'REVISING' || _vm.detail.applymentStatus === 'CORRECTED' || _vm.detail.applymentStatus === 'ILLEGAL' || _vm.detail.applymentStatus === 'AUDIT_FAIL' || _vm.detail.applymentStatus === 'REFUND_APPLY_DENY' || _vm.detail.applymentStatus === 'REFUND_APPLY_REJECT' || _vm.detail.applymentStatus === 'ACRA_REJECTED') && _vm.isApplymentAuth('company:coordinate') ? _c('el-button', {
    ref: "",
    on: {
      "click": function ($event) {
        return _vm.openEdit();
      }
    }
  }, [_vm._v(" Edit ")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Registered Office")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self no-event"
  }, [_c('el-radio-group', {
    attrs: {
      "value": _vm.detail.registeredOfficeType
    }
  }, _vm._l(_vm.registerOfficeTypeList, function (item) {
    return _c('div', {
      key: item.value
    }, [_c('el-radio', {
      staticClass: "pt15 pb15",
      attrs: {
        "label": item.value
      }
    }, [_c('span', [_vm._v(_vm._s(item.title))]), item.value === 1 && _vm.detail.registeredOfficeType === 1 ? _c('div', {
      staticClass: "mt15 ml24"
    }, [_c('el-radio-group', {
      attrs: {
        "value": _vm.detail.registeredOfficeId
      }
    }, _vm._l(_vm.nativeRegisteredAddress, function (office) {
      return _c('el-radio', {
        key: office.id,
        attrs: {
          "label": office.id
        }
      }, [_vm._v(_vm._s(_vm.formatNativeAddress(office)))]);
    }), 1)], 1) : _vm._e(), item.value === 2 && _vm.detail.registeredOfficeType === 2 ? _c('div', {
      staticClass: "mt10 ml24"
    }, [_vm._v(" " + _vm._s(_vm.formatCustomAddress(_vm.detail)) + " ")]) : _vm._e()])], 1);
  }), 0)], 1)])]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Working hours")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self no-event"
  }, [_c('el-row', [_c('el-col', {
    attrs: {
      "span": 16
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "value": _vm.detail.workingHours
    }
  }, _vm._l(_vm.workingHoursOptions, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1)], 1)])]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Constitution")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self no-event"
  }, [_c('el-row', [_c('el-col', {
    attrs: {
      "span": 16
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "value": _vm.detail.isCustomizedConstitution
    }
  }, _vm._l(_vm.customizedConstitutionOptions, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1)], 1)])]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Is the company's business special and needs to obtain approval(s) from specific government authorities?")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self no-event"
  }, [_c('el-radio-group', {
    model: {
      value: _vm.detail.hasObtainedApproval,
      callback: function ($$v) {
        _vm.$set(_vm.detail, "hasObtainedApproval", $$v);
      },
      expression: "detail.hasObtainedApproval"
    }
  }, _vm._l(_vm.checkboxOptions, function (item) {
    return _c('el-radio', {
      key: item.value,
      attrs: {
        "label": item.value
      }
    }, [_vm._v(_vm._s(item.title))]);
  }), 1), _vm.detail.hasObtainedApproval === 1 ? _c('el-checkbox-group', {
    attrs: {
      "value": _vm.detail.approvalAuthorities || []
    }
  }, _vm._l(_vm.authoritiesOptions, function (item) {
    return _c('el-checkbox', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1) : _vm._e()], 1)])]), _vm.editVisible ? _c('edit-modal', {
    ref: "editModal",
    attrs: {
      "checkboxOptions": _vm.checkboxOptions,
      "workingHoursOptions": _vm.workingHoursOptions,
      "customizedConstitutionOptions": _vm.customizedConstitutionOptions,
      "authoritiesOptions": _vm.authoritiesOptions,
      "nativeRegisteredAddress": _vm.nativeRegisteredAddress,
      "registerOfficeTypeList": _vm.registerOfficeTypeList,
      "businessType": _vm.detail.businessType
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }