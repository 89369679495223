import request from "@/utils/http";

function markFiles(payload) {
  const copyNewVal = JSON.parse(JSON.stringify(payload));
  const {
    companyMemberList,
    companyFundMemberList,
    registeredOfficeAddressCertificateUrls,
    registeredOfficeShareholdingStructureUrls,
    authoritiesApprovalFileUrls,
    legalConstitutionUrls,
    applicantIdentificationUrls,
    authorizationUrls,
    transferBusinessProfileAndArticleUrls,
    transferBusinessActivityDocUrls,
    transferShareholdingStructureUrls,
    transferAnnualFinancialReportUrls,
    transferRegisteredOfficeAddressCertificateUrls,
    transferSecretaryParticularsUrls,
    financeTaxUrls,
    fundInfoList,
    fundsShareholdingStructureUrls,
    promotionCertifiedUrls,
    questionnaire,
  } = copyNewVal;
  if (companyMemberList) {
    companyMemberList.forEach((member, idx) => {
      member?.identificationUrls?.forEach((url, index) => {
        url.index = index;
        url.type = "identificationUrls";
        url.belong = "member";
        url.memberIdx = idx;
      });
      member?.holdingIdentificationUrls?.forEach((url, index) => {
        url.index = index;
        url.type = "holdingIdentificationUrls";
        url.belong = "member";
        url.memberIdx = idx;
      });
      member?.addressIdUrls?.forEach((url, index) => {
        url.index = index;
        url.type = "addressIdUrls";
        url.belong = "member";
        url.memberIdx = idx;
      });
      member?.businessLicenseUrls?.forEach((url, index) => {
        url.index = index;
        url.type = "businessLicenseUrls";
        url.belong = "member";
        url.memberIdx = idx;
      });
    });
  }
  // 公司设立
  if (registeredOfficeAddressCertificateUrls) {
    registeredOfficeAddressCertificateUrls?.forEach((url, index) => {
      url.index = index;
      url.type = "registeredOfficeAddressCertificateUrls";
    });
  }
  if (registeredOfficeShareholdingStructureUrls) {
    registeredOfficeShareholdingStructureUrls?.forEach((url, index) => {
      url.index = index;
      url.type = "registeredOfficeShareholdingStructureUrls";
    });
  }
  if (authoritiesApprovalFileUrls) {
    authoritiesApprovalFileUrls?.forEach((url, index) => {
      url.index = index;
      url.type = "authoritiesApprovalFileUrls";
    });
  }
  if (legalConstitutionUrls) {
    legalConstitutionUrls?.forEach((url, index) => {
      url.index = index;
      url.type = "legalConstitutionUrls";
    });
  }
  if (applicantIdentificationUrls) {
    applicantIdentificationUrls?.forEach((url, index) => {
      url.index = index;
      url.type = "applicantIdentificationUrls";
    });
  }
  if (authorizationUrls) {
    authorizationUrls?.forEach((url, index) => {
      url.index = index;
      url.type = "authorizationUrls";
    });
  }
  // 服务转移
  if (transferBusinessProfileAndArticleUrls) {
    transferBusinessProfileAndArticleUrls?.forEach((url, index) => {
      url.index = index;
      url.type = "transferBusinessProfileAndArticleUrls";
    });
  }
  if (transferBusinessActivityDocUrls) {
    transferBusinessActivityDocUrls?.forEach((url, index) => {
      url.index = index;
      url.type = "transferBusinessActivityDocUrls";
    });
  }
  if (transferShareholdingStructureUrls) {
    transferShareholdingStructureUrls?.forEach((url, index) => {
      url.index = index;
      url.type = "transferShareholdingStructureUrls";
    });
  }
  if (transferAnnualFinancialReportUrls) {
    transferAnnualFinancialReportUrls?.forEach((url, index) => {
      url.index = index;
      url.type = "transferAnnualFinancialReportUrls";
    });
  }
  if (transferRegisteredOfficeAddressCertificateUrls) {
    transferRegisteredOfficeAddressCertificateUrls?.forEach((url, index) => {
      url.index = index;
      url.type = "transferRegisteredOfficeAddressCertificateUrls";
    });
  }
  if (transferSecretaryParticularsUrls) {
    transferSecretaryParticularsUrls?.forEach((url, index) => {
      url.index = index;
      url.type = "transferSecretaryParticularsUrls";
    });
  }

  // 综合性公司服务
  if (financeTaxUrls) {
    financeTaxUrls?.forEach((url, index) => {
      url.index = index;
      url.type = "financeTaxUrls";
    });
  }

  // 基金托管服务
  if (fundInfoList) {
    fundInfoList.forEach((admin, idx) => {
      admin?.fundBusinessLicenseUrls?.forEach((url, index) => {
        url.index = index;
        url.type = "fundBusinessLicenseUrls";
        url.belong = "admin";
        url.adminIdx = idx;
      });
      admin?.fundInfoUrls?.forEach((url, index) => {
        url.index = index;
        url.type = "fundInfoUrls";
        url.belong = "admin";
        url.adminIdx = idx;
      });
      admin?.fundMemoUrls?.forEach((url, index) => {
        url.index = index;
        url.type = "fundMemoUrls";
        url.belong = "admin";
        url.adminIdx = idx;
      });
    });
  }
  if (companyFundMemberList) {
    companyFundMemberList.forEach((fund, idx) => {
      fund?.identificationUrls?.forEach((url, index) => {
        url.index = index;
        url.type = "identificationUrls";
        url.belong = "fund";
        url.fundIdx = idx;
      });
      fund?.holdingIdentificationUrls?.forEach((url, index) => {
        url.index = index;
        url.type = "holdingIdentificationUrls";
        url.belong = "fund";
        url.fundIdx = idx;
      });
      fund?.addressIdUrls?.forEach((url, index) => {
        url.index = index;
        url.type = "addressIdUrls";
        url.belong = "fund";
        url.fundIdx = idx;
      });
      fund?.businessLicenseUrls?.forEach((url, index) => {
        url.index = index;
        url.type = "businessLicenseUrls";
        url.belong = "fund";
        url.fundIdx = idx;
      });
    });
  }
  if (fundsShareholdingStructureUrls) {
    fundsShareholdingStructureUrls?.forEach((url, index) => {
      url.index = index;
      url.type = "fundsShareholdingStructureUrls";
    });
  }
  if (promotionCertifiedUrls) {
    promotionCertifiedUrls?.forEach((url, index) => {
      url.index = index;
      url.type = "promotionCertifiedUrls";
    });
  }

  // 所有的服务类型
  if (questionnaire) {
    // 最终受益人的身份证件/营业执照和地址证明
    const { ultimateBeneficiaryInfo } = questionnaire;
    if (ultimateBeneficiaryInfo) {
      ultimateBeneficiaryInfo.forEach((item, idx) => {
        item?.ultimateBeneficiaryBusinessLicenseUrls?.forEach((url, index) => {
          url.index = index;
          url.type = "ultimateBeneficiaryBusinessLicenseUrls";
          url.belong = "benefit";
          url.benefitIdx = idx;
        });
      });
    }
  }
  return copyNewVal;
}

export default {
  namespaced: true,
  state: {
    detail: {},
  },
  mutations: {
    INIT_DETAIL(state, detail) {
      const markedData = markFiles(detail);
      state.detail = { ...markedData };
    },
    SET_DETAIL(state, detail) {
      const markedData = markFiles(detail);
      state.detail = { ...state.detail, ...markedData };
    },
  },
  getters: {
    getOwnershipList: ({ detail }) => {
      const { coordinator, checker, auditor } = detail;
      return {
        coordinator,
        checker,
        auditor,
      };
    },
    companyMemberFiles: ({ detail }) => {
      const { companyMemberList } = detail;
      return (
        companyMemberList &&
        companyMemberList.reduce((obj, item) => {
          const km = {
            identificationFiles: "identificationUrls",
            holdingIdentificationFiles: "holdingIdentificationUrls",
            addressFiles: "addressIdUrls",
            businessLicenseFiles: "businessLicenseUrls",
          };
          Object.keys(km).forEach(k => {
            const m = km[k];
            if (item[m]) {
              if (item[m] && item[m].length > 0) {
                if (!obj[k]) {
                  obj[k] = [];
                }
                obj[k].push({
                  id: item.id,
                  fullName: item.fullName,
                  positionHeld: item.positionHeld,
                  fileUrls: item[m],
                });
              }
            }
          });
          return obj;
        }, {})
      );
    },
    companyFundMemberFiles: ({ detail }) => {
      const { companyFundMemberList } = detail;
      return (
        companyFundMemberList &&
        companyFundMemberList.reduce((obj, item) => {
          const km = {
            identificationFiles: "identificationUrls",
            holdingIdentificationFiles: "holdingIdentificationUrls",
            addressFiles: "addressIdUrls",
            businessLicenseFiles: "businessLicenseUrls",
          };
          Object.keys(km).forEach(k => {
            const m = km[k];
            if (item[m]) {
              if (item[m] && item[m].length > 0) {
                if (!obj[k]) {
                  obj[k] = [];
                }
                obj[k].push({
                  id: item.id,
                  fullName: item.fullName,
                  positionHeld: item.positionHeld,
                  fileUrls: item[m],
                });
              }
            }
          });
          return obj;
        }, {})
      );
    },
  },
  actions: {
    //申请单详情
    async initApplyment({ commit }, applymentId) {
      const { data } = await request.get(`/admin/companyApplymentManage/${applymentId}/detail`);
      commit("INIT_DETAIL", data);
      return Promise.resolve(data);
    },
    //初审
    doCheck({ commit, state }, applymentStatus) {
      return new Promise((resolve, reject) => {
        request
          .post(`/admin/companyApplymentStatusManage/check`, {
            applymentId: state.detail.id,
            applymentStatus: applymentStatus,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              const newDetail = { ...this.detail, applymentStatus };
              commit("SET_DETAIL", newDetail);
              resolve();
            } else {
              reject(msg);
            }
          });
      });
    },
    //发起退款申请
    doApplyRefund({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        request.post(`/admin/companyApplymentStatusManage/applyRefund`, payload).then(({ code, msg }) => {
          if (code === 200) {
            const newDetail = { ...this.detail, applymentStatus: "REFUND_APPLYING" };
            commit("SET_DETAIL", newDetail);
            resolve();
          } else {
            reject(msg);
          }
        });
      });
    },
    // 退款审核 - 初审
    doRefundCheck({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        request
          .post(`/admin/companyApplymentStatusManage/check`, {
            applymentId: state.detail.id,
            ...payload,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              const newDetail = { ...this.detail, applymentStatus: payload.applymentStatus };
              commit("SET_DETAIL", newDetail);
              resolve();
            } else {
              reject(msg);
            }
          });
      });
    },
    //复核
    doAudit({ commit, state }, applymentStatus) {
      return new Promise((resolve, reject) => {
        request
          .post(`/admin/companyApplymentStatusManage/audit`, {
            applymentId: state.detail.id,
            applymentStatus: applymentStatus,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              const newDetail = { ...this.detail, applymentStatus };
              commit("SET_DETAIL", newDetail);
              resolve();
            } else {
              reject(msg);
            }
          });
      });
    },
    //复核 - 批量
    doAuditBatch({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        request
          .post(`/admin/companyApplymentStatusManage/batchAudit`, {
            ...payload,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              resolve();
            } else {
              reject(msg);
            }
          });
      });
    },
    //退款审核-复核
    doRefundAudit({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        request
          .post(`/admin/companyApplymentStatusManage/audit`, {
            applymentId: state.detail.id,
            ...payload,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              const newDetail = { ...this.detail, applymentStatus: payload.applymentStatus };
              commit("SET_DETAIL", newDetail);
              resolve();
            } else {
              reject(msg);
            }
          });
      });
    },
    //ACRA申请-业务
    doAcraApply({ commit, state }) {
      return new Promise((resolve, reject) => {
        request
          .post(`/admin/companyApplymentStatusManage/acraApply`, {
            applymentId: state.detail.id,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              const newDetail = { ...this.detail, applymentStatus: "ACRA_APPLYING" };
              commit("SET_DETAIL", newDetail);
              resolve();
            } else {
              reject(msg);
            }
          });
      });
    },
    //ACRA反馈 - 业务
    doAcraFeedback({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        request
          .post(`/admin/companyApplymentStatusManage/acraFeedback`, {
            applymentId: state.detail.id,
            ...payload,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              let newDetail = null;
              if (payload.onlyTemporarilySaved) {
                newDetail = { ...this.detail, statusDetail: payload };
              } else {
                newDetail = { ...this.detail, applymentStatus: payload.applymentStatus };
              }
              commit("SET_DETAIL", newDetail);
              resolve();
            } else {
              reject(msg);
            }
          });
      });
    },
    //退款信息补充 - 业务
    doRefundReplenish({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        request
          .post(`/admin/orderRefundManage/updateRefundInfo`, {
            refundId: payload.orderInfo.orderRefund.id,
            remark: payload.remark,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              const newDetail = { ...this.detail, orderInfo: payload.orderInfo };
              commit("SET_DETAIL", newDetail);
              resolve();
            } else {
              reject(msg);
            }
          });
      });
    },
    //驳回
    doReject({ commit, state }) {
      return new Promise((resolve, reject) => {
        const applymentStatus = "REJECTED";
        request
          .post(`/admin/companyApplymentStatusManage/reject`, {
            applymentId: state.detail.id,
            applymentStatus: applymentStatus,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              const newDetail = { ...this.detail, applymentStatus };
              commit("SET_DETAIL", newDetail);
              resolve();
            } else {
              reject(msg);
            }
          });
      });
    },
    //修改
    doRevise({ commit, state }) {
      return new Promise((resolve, reject) => {
        const applymentStatus = "REVISE_FINISH";
        request.post(`/admin/companyApplymentStatusManage/${state.detail.id}/commitRevision`).then(({ code, msg }) => {
          if (code === 200) {
            const newDetail = { ...this.detail, applymentStatus };
            commit("SET_DETAIL", newDetail);
            resolve();
          } else {
            reject(msg);
          }
        });
      });
    },
    //更新步骤
    onlyUpdateProcedures({ commit, state }, stepData) {
      const copy = { ...stepData };
      let procedures = [...state.detail.procedures];
      const index = procedures.findIndex(i => i.step === copy.step);
      if (index !== -1) {
        procedures[index] = { ...procedures[index], ...copy };
      } else {
        procedures = [...procedures, copy];
      }
      commit("SET_DETAIL", { procedures });
    },
    //保存步骤审核
    doSaveProcedures({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        request.post(`/admin/companyApplymentStatusProcedure/save`, payload).then(({ code, msg }) => {
          if (code === 200) {
            dispatch("onlyUpdateProcedures", payload);
            resolve();
          } else {
            reject(msg);
          }
        });
      });
    },
    //更新数据
    onlyUpdateDetail({ commit }, payload) {
      const newDetail = JSON.parse(JSON.stringify(payload));
      commit("SET_DETAIL", newDetail);
      return Promise.resolve();
    },
    // 修改申请人角色
    doSaveApplicantRole({ state, commit }, role) {
      return new Promise((resolve, reject) => {
        const newDetail = JSON.parse(JSON.stringify(state.detail));
        const applymentStatus = "REVISING";
        request.post(`/admin/companyApplymentStatusManage/revise`, { id: newDetail.id, applicantPosition: role }).then(({ code, msg }) => {
          if (code === 200) {
            newDetail.applymentStatus = applymentStatus;
            newDetail.applicantPosition = role;
            commit("SET_DETAIL", newDetail);
            resolve();
          } else {
            reject(msg);
          }
        });
      });
    },
    //保存修改
    doSaveRevising({ state, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const applymentStatus = "REVISING";
        //步骤状态为已修改
        payload.procedure = {
          ...payload.procedure,
          applymentId: payload.id,
          status: 2,
        };
        request.post(`/admin/companyApplymentStatusManage/revise`, payload).then(({ code, msg }) => {
          if (code === 200) {
            const newDetail = JSON.parse(JSON.stringify(payload));
            newDetail.applymentStatus = applymentStatus;
            commit("SET_DETAIL", newDetail);
            dispatch("onlyUpdateProcedures", payload.procedure);
            resolve();
          } else {
            reject(msg);
          }
        });
      });
    },
    //更新文件
    doUpdateFiles({ state, commit }, file) {
      return new Promise((resolve, reject) => {
        const newDetail = JSON.parse(JSON.stringify(state.detail));
        if (file.belong && file.belong === "member") {
          newDetail.companyMemberList[file.memberIdx][file.type][file.index] = file;
        } else if (file.belong && file.belong === "fund") {
          newDetail.companyFundMemberList[file.fundIdx][file.type][file.index] = file;
        } else if (file.belong && file.belong === "admin") {
          newDetail.fundInfoList[file.adminIdx][file.type][file.index] = file;
        } else if (file.belong && file.belong === "benefit") {
          newDetail.questionnaire.ultimateBeneficiaryInfo[file.benefitIdx][file.type][file.index] = file;
          newDetail.ultimateBeneficiaryInfo = newDetail.questionnaire.ultimateBeneficiaryInfo;
        } else {
          newDetail[file.type][file.index] = file;
        }
        request.post(`/admin/companyApplymentStatusManage/updateFiles`, newDetail).then(({ code, msg }) => {
          if (code === 200) {
            commit("SET_DETAIL", newDetail);
            resolve();
          } else {
            reject(msg);
          }
        });
      });
    },
    //认领
    doClaimOwnership({ state, commit }, { ownershipRole, toUser }) {
      return new Promise((resolve, reject) => {
        const newDetail = JSON.parse(JSON.stringify(state.detail));
        newDetail[ownershipRole] = toUser;
        request
          .post(`/admin/companyApplymentStatusManage/claimOwnership`, {
            applymentId: newDetail.id,
            ownershipRole: ownershipRole,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              if (newDetail.applymentStatus === "SUBMITTED") {
                newDetail.applymentStatus = "CHECKING";
              }
              commit("SET_DETAIL", newDetail);
              resolve();
            } else {
              reject(msg);
            }
          });
      });
    },
    //转让
    doTransferOwnership({ state, commit }, { ownershipRole, toUser }) {
      return new Promise((resolve, reject) => {
        const newDetail = JSON.parse(JSON.stringify(state.detail));
        newDetail[ownershipRole] = toUser;
        request
          .post(`/admin/companyApplymentStatusManage/transferOwnership`, {
            applymentId: newDetail.id,
            ownershipRole: ownershipRole,
            to: toUser.id,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              commit("SET_DETAIL", newDetail);
              resolve();
            } else {
              reject(msg);
            }
          });
      });
    },
    //调整
    doAdjustOwnership({ state, commit }, { ownershipRole, toUser }) {
      return new Promise((resolve, reject) => {
        const newDetail = JSON.parse(JSON.stringify(state.detail));
        newDetail[ownershipRole] = toUser;
        request
          .post(`/admin/companyApplymentStatusManage/adjustOwnership`, {
            applymentId: newDetail.id,
            ownershipRole: ownershipRole,
            to: toUser.id,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              if (newDetail.applymentStatus === "SUBMITTED") {
                newDetail.applymentStatus = "CHECKING";
              }
              commit("SET_DETAIL", newDetail);
              resolve();
            } else {
              reject(msg);
            }
          });
      });
    },
    //下补款单 - 业务
    doMakeAmends({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        request
          .post(`/admin/orderManage/makeAmends`, {
            businessId: state.detail.id,
            ...payload,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              const newDetail = { ...this.detail, amends: 1 };
              commit("SET_DETAIL", newDetail);
              resolve();
            } else {
              reject(msg);
            }
          })
          .catch(msg => {
            reject(msg);
          });
      });
    },
    //关闭补款单 - 业务
    doCloseAmends({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        request
          .post(`/admin/orderManage/closeAmends`, {
            ...payload,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              const newDetail = { ...this.detail, amends: -1 };
              commit("SET_DETAIL", newDetail);
              resolve();
            } else {
              reject(msg);
            }
          });
      });
    },
    //创建报价单 - 业务
    createQuotation({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        request
          .post("/admin/matterQuotationManage/create", payload)
          .then(({ code, msg }) => {
            if (code === 200) {
              // const newDetail = { ...this.detail, applymentStatus: "QUOTING", quotationList: payload.contentList };
              // commit("SET_DETAIL", newDetail);
              resolve();
            } else {
              reject(msg);
            }
          })
          .catch(msg => {
            reject(msg);
          });
      });
    },
    //更新报价单 - 业务
    updateQuotation({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        request
          .post("/admin/matterQuotationManage/update", payload)
          .then(({ code, msg }) => {
            if (code === 200) {
              // const newDetail = { ...this.detail, applymentStatus: "QUOTING", quotationList: payload.contentList };
              // commit("SET_DETAIL", newDetail);
              resolve();
            } else {
              reject(msg);
            }
          })
          .catch(msg => {
            reject(msg);
          });
      });
    },
    //提交报价单 - 业务
    commitQuotation({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        request
          .post("/admin/companyApplymentStatusManage/commitQuotation", payload)
          .then(({ code, msg }) => {
            if (code === 200) {
              const newDetail = { ...this.detail, applymentStatus: "QUOTATION_AUDITING" };
              commit("SET_DETAIL", newDetail);
              resolve();
            } else {
              reject(msg);
            }
          })
          .catch(msg => {
            reject(msg);
          });
      });
    },
    //报价单审核 - 复核
    auditQuotation({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        request
          .post(`/admin/companyApplymentStatusManage/audit`, {
            applymentId: state.detail.id,
            ...payload,
          })
          .then(({ code, msg }) => {
            if (code === 200) {
              const newDetail = { ...this.detail, applymentStatus: payload.applymentStatus };
              commit("SET_DETAIL", newDetail);
              resolve();
            } else {
              reject(msg);
            }
          });
      });
    },
    //保存或更新收费协议 - 业务
    saveFeeLetter({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        request
          .post("/admin/customerFeeLetterManage/createOrUp", payload)
          .then(({ code, msg }) => {
            if (code === 200) {
              const newDetail = { ...this.detail, applymentStatus: "QUOTING" };
              commit("SET_DETAIL", newDetail);
              resolve();
            } else {
              reject(msg);
            }
          })
          .catch(msg => {
            reject(msg);
          });
      });
    },
    //发起退款列表申请
    doApplyRefundList({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        request.post(`/admin/companyApplymentStatusManage/applyRefundList`, payload).then(({ code, msg }) => {
          if (code === 200) {
            const newDetail = { ...this.detail, applymentStatus: "REFUND_APPLYING" };
            commit("SET_DETAIL", newDetail);
            resolve();
          } else {
            reject(msg);
          }
        });
      });
    },
    //发起终止交易申请 - 业务
    doApplyTerminate({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        request.post("/admin/companyApplymentStatusManage/applyTerminateTransaction", payload).then(({ code, msg }) => {
          if (code === 200) {
            const newDetail = { ...this.detail, applymentStatus: "SUSPEND_TRANSACTION_APPLYING" };
            commit("SET_DETAIL", newDetail);
            resolve();
          } else {
            reject(msg);
          }
        });
      });
    },
    //驳回终止交易申请 - 复核
    doTerminateReject({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        request.post("/admin/companyApplymentStatusManage/rejectTerminateTransaction", payload).then(({ code, msg }) => {
          if (code === 200) {
            const newDetail = { ...this.detail, applymentStatus: "SUSPEND_TRANSACTION_APPLY_REJECT" };
            commit("SET_DETAIL", newDetail);
            resolve();
          } else {
            reject(msg);
          }
        });
      });
    },
    //通过终止交易申请 - 复核
    doTerminatePass({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        request.post("/admin/companyApplymentStatusManage/terminateTransaction", payload).then(({ code, msg }) => {
          if (code === 200) {
            const newDetail = { ...this.detail, applymentStatus: "TERMINATED" };
            commit("SET_DETAIL", newDetail);
            resolve();
          } else {
            reject(msg);
          }
        });
      });
    },
  },
};
