var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.detail.fundInfoList.length > 0 ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }), _vm._m(0), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('div', {
    staticClass: "shareholder-files"
  }, _vm._l(_vm.detail.fundInfoList, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "shareholder-file-wrapper"
    }, [item.fundBusinessLicenseUrls && item.fundBusinessLicenseUrls.length > 0 && item.fundInfoUrls && item.fundInfoUrls.length > 0 && item.fundMemoUrls && item.fundMemoUrls.length > 0 ? [_c('div', {
      staticClass: "member-info"
    }, [_c('span', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.fundName))]), _c('span', {
      staticClass: "held"
    }, [_vm._v(_vm._s(item.fundType))])]), _c('div', {
      staticClass: "file-urls"
    }, [_c('company-file-list', {
      attrs: {
        "fileUrls": item.fundBusinessLicenseUrls.concat(item.fundMemoUrls.concat(item.fundInfoUrls)),
        "detail": _vm.detail
      }
    })], 1)] : _vm._e()], 2);
  }), 0)])])]) : _vm._e(), _vm.fundIcMerge.length > 0 ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }), _vm._m(1), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('div', {
    staticClass: "shareholder-files"
  }, _vm._l(_vm.fundIcMerge, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "shareholder-file-wrapper"
    }, [item.fileUrls && item.fileUrls.length > 0 ? [_c('div', {
      staticClass: "member-info"
    }, [_c('span', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.fullName))]), _c('span', {
      staticClass: "held"
    }, [_vm._v(_vm._s(_vm.positionHeldFormat(item.positionHeld)))])]), _c('div', {
      staticClass: "file-urls"
    }, [_c('company-file-list', {
      attrs: {
        "fileUrls": item.fileUrls,
        "detail": _vm.detail
      }
    })], 1)] : _vm._e()], 2);
  }), 0)])])]) : _vm._e(), _vm.companyFundMemberFiles && _vm.companyFundMemberFiles.addressFiles ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }), _vm._m(2), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('div', {
    staticClass: "shareholder-files"
  }, _vm._l(_vm.companyFundMemberFiles.addressFiles, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "shareholder-file-wrapper"
    }, [item.fileUrls && item.fileUrls.length > 0 ? [_c('div', {
      staticClass: "member-info"
    }, [_c('span', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.fullName))]), _c('span', {
      staticClass: "held"
    }, [_vm._v(_vm._s(_vm.positionHeldFormat(item.positionHeld)))])]), _c('div', {
      staticClass: "file-urls"
    }, [_c('company-file-list', {
      attrs: {
        "fileUrls": item.fileUrls,
        "detail": _vm.detail
      }
    })], 1)] : _vm._e()], 2);
  }), 0)])])]) : _vm._e(), _vm.companyFundMemberFiles && _vm.companyFundMemberFiles.businessLicenseFiles ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }), _vm._m(3), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('div', {
    staticClass: "shareholder-files"
  }, _vm._l(_vm.companyFundMemberFiles.businessLicenseFiles, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "shareholder-file-wrapper"
    }, [item.fileUrls && item.fileUrls.length > 0 ? [_c('div', {
      staticClass: "member-info"
    }, [_c('span', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.fullName))]), _c('span', {
      staticClass: "held"
    }, [_vm._v(_vm._s(_vm.positionHeldFormat(item.positionHeld)))])]), _c('div', {
      staticClass: "file-urls"
    }, [_c('company-file-list', {
      attrs: {
        "fileUrls": item.fileUrls,
        "detail": _vm.detail
      }
    })], 1)] : _vm._e()], 2);
  }), 0)])])]) : _vm._e(), _vm.detail.fundsShareholdingStructureUrls && _vm.detail.fundsShareholdingStructureUrls.length > 0 ? _c('div', {
    staticClass: "desp-items-group"
  }, [_vm._m(4), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('company-file-list', {
    attrs: {
      "fileUrls": _vm.detail.fundsShareholdingStructureUrls,
      "detail": _vm.detail
    }
  })], 1)])]) : _vm._e(), _vm.passportIcMerge.length > 0 ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }), _vm._m(5), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('div', {
    staticClass: "shareholder-files"
  }, _vm._l(_vm.passportIcMerge, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "shareholder-file-wrapper"
    }, [item.fileUrls && item.fileUrls.length > 0 ? [_c('div', {
      staticClass: "member-info"
    }, [_c('span', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.fullName))]), _c('span', {
      staticClass: "held"
    }, [_vm._v(_vm._s(_vm.positionHeldFormat(item.positionHeld)))])]), _c('div', {
      staticClass: "file-urls"
    }, [_c('company-file-list', {
      attrs: {
        "fileUrls": item.fileUrls,
        "detail": _vm.detail
      }
    })], 1)] : _vm._e()], 2);
  }), 0)])])]) : _vm._e(), _vm.companyMemberFiles && _vm.companyMemberFiles.addressFiles ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }), _vm._m(6), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('div', {
    staticClass: "shareholder-files"
  }, _vm._l(_vm.companyMemberFiles.addressFiles, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "shareholder-file-wrapper"
    }, [item.fileUrls && item.fileUrls.length > 0 ? [_c('div', {
      staticClass: "member-info"
    }, [_c('span', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.fullName))]), _c('span', {
      staticClass: "held"
    }, [_vm._v(_vm._s(_vm.positionHeldFormat(item.positionHeld)))])]), _c('div', {
      staticClass: "file-urls"
    }, [_c('company-file-list', {
      attrs: {
        "fileUrls": item.fileUrls,
        "detail": _vm.detail
      }
    })], 1)] : _vm._e()], 2);
  }), 0)])])]) : _vm._e(), _vm.companyMemberFiles && _vm.companyMemberFiles.businessLicenseFiles ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }), _vm._m(7), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('div', {
    staticClass: "shareholder-files"
  }, _vm._l(_vm.companyMemberFiles.businessLicenseFiles, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "shareholder-file-wrapper"
    }, [item.fileUrls && item.fileUrls.length > 0 ? [_c('div', {
      staticClass: "member-info"
    }, [_c('span', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.fullName))]), _c('span', {
      staticClass: "held"
    }, [_vm._v(_vm._s(_vm.positionHeldFormat(item.positionHeld)))])]), _c('div', {
      staticClass: "file-urls"
    }, [_c('company-file-list', {
      attrs: {
        "fileUrls": item.fileUrls,
        "detail": _vm.detail
      }
    })], 1)] : _vm._e()], 2);
  }), 0)])])]) : _vm._e(), _vm.detail.transferBusinessProfileAndArticleUrls && _vm.detail.transferBusinessProfileAndArticleUrls.length > 0 ? _c('div', {
    staticClass: "desp-items-group"
  }, [_vm._m(8), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('company-file-list', {
    attrs: {
      "fileUrls": _vm.detail.transferBusinessProfileAndArticleUrls,
      "detail": _vm.detail
    }
  })], 1)])]) : _vm._e(), _vm.detail.transferShareholdingStructureUrls && _vm.detail.transferShareholdingStructureUrls.length > 0 ? _c('div', {
    staticClass: "desp-items-group"
  }, [_vm._m(9), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('company-file-list', {
    attrs: {
      "fileUrls": _vm.detail.transferShareholdingStructureUrls,
      "detail": _vm.detail
    }
  })], 1)])]) : _vm._e(), _vm.detail.transferAnnualFinancialReportUrls && _vm.detail.transferAnnualFinancialReportUrls.length > 0 ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }), _vm._m(10), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('company-file-list', {
    attrs: {
      "fileUrls": _vm.detail.transferAnnualFinancialReportUrls,
      "detail": _vm.detail
    }
  })], 1)])]) : _vm._e(), _vm.detail.transferRegisteredOfficeAddressCertificateUrls && _vm.detail.transferRegisteredOfficeAddressCertificateUrls.length > 0 ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }), _vm._m(11), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('company-file-list', {
    attrs: {
      "fileUrls": _vm.detail.transferRegisteredOfficeAddressCertificateUrls,
      "detail": _vm.detail
    }
  })], 1)])]) : _vm._e(), _vm.detail.transferSecretaryParticularsUrls && _vm.detail.transferSecretaryParticularsUrls.length > 0 ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }), _vm._m(12), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('company-file-list', {
    attrs: {
      "fileUrls": _vm.detail.transferSecretaryParticularsUrls,
      "detail": _vm.detail
    }
  })], 1)])]) : _vm._e(), _vm.detail.financeTaxUrls && _vm.detail.financeTaxUrls.length > 0 ? _c('div', {
    staticClass: "desp-items-group"
  }, [_vm._m(13), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('company-file-list', {
    attrs: {
      "fileUrls": _vm.detail.financeTaxUrls,
      "detail": _vm.detail
    }
  })], 1)])]) : _vm._e(), _vm.detail.applicantPosition === 'INDIRECT_SHAREHOLDER' || _vm.detail.applicantPosition === 'OTHER' ? [_vm.detail.authorizationUrls && _vm.detail.authorizationUrls.length > 0 || _vm.detail.applicantIdentificationUrls && _vm.detail.applicantIdentificationUrls.length > 0 ? _c('div', {
    staticClass: "desp-items-group"
  }, [_vm._m(14), _c('div', {
    staticClass: "desp-item"
  }, [_vm.detail.applicantPosition === 'OTHER' && _vm.detail.authorizationUrls && _vm.detail.authorizationUrls.length > 0 ? _c('div', {
    staticClass: "desp-item-self"
  }, [_c('company-file-list', {
    attrs: {
      "fileUrls": _vm.detail.authorizationUrls,
      "detail": _vm.detail
    }
  })], 1) : _vm._e(), (_vm.detail.applicantPosition === 'INDIRECT_SHAREHOLDER' || _vm.detail.applicantPosition === 'OTHER') && _vm.detail.applicantIdentificationUrls && _vm.detail.applicantIdentificationUrls.length > 0 ? _c('div', {
    staticClass: "desp-item-self"
  }, [_c('company-file-list', {
    attrs: {
      "fileUrls": _vm.detail.applicantIdentificationUrls,
      "detail": _vm.detail
    }
  })], 1) : _vm._e()])]) : _vm._e()] : _vm._e(), _vm.detail.promotionCertifiedUrls && _vm.detail.promotionCertifiedUrls.length > 0 ? _c('div', {
    staticClass: "desp-items-group"
  }, [_vm._m(15), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('company-file-list', {
    attrs: {
      "fileUrls": _vm.detail.promotionCertifiedUrls,
      "detail": _vm.detail
    }
  })], 1)])]) : _vm._e(), _vm.detail.questionnaire && _vm.detail.questionnaire.ultimateBeneficiaryInfo && _vm.detail.questionnaire.ultimateBeneficiaryInfo.length > 0 ? [_vm.isUltimateBeneficiaryShow ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }), _vm._m(16), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('div', {
    staticClass: "shareholder-files"
  }, _vm._l(_vm.detail.questionnaire.ultimateBeneficiaryInfo, function (item, idx) {
    return _c('div', {
      key: `ultimateBeneficiaryInfo${idx}`,
      staticClass: "shareholder-file-wrapper"
    }, [item.ultimateBeneficiaryBusinessLicenseUrls && item.ultimateBeneficiaryBusinessLicenseUrls.length > 0 ? [_c('div', {
      staticClass: "member-info"
    }, [_c('span', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.fullName))]), _c('span', {
      staticClass: "held"
    }, [_vm._v(_vm._s(_vm.memberTypeFormat(item.memberType)))])]), item.ultimateBeneficiaryBusinessLicenseUrls ? _c('div', {
      staticClass: "file-urls"
    }, [_c('company-file-list', {
      attrs: {
        "fileUrls": item.ultimateBeneficiaryBusinessLicenseUrls,
        "detail": _vm.detail
      }
    })], 1) : _vm._e()] : _vm._e()], 2);
  }), 0)])])]) : _vm._e()] : _vm._e()], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp-items-group-header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Business Information of the Proposed Funds")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp-items-group-header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Passport / IC of Individual Shareholder(s) and Director(s) of the Fund(s)")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp-items-group-header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Proof of Resident/Company Address of the Fund(s) – Scanned Copy")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp-items-group-header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Corporate Shareholder's Business Certificate & Articles of Association of the Fund(s) – Scanned Copy")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp-items-group-header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Shareholder Structure of the Proposed Fund(s)")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp-items-group-header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Passport / IC of Individual Shareholder(s) and Director(s) of the Fund Manager")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp-items-group-header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Proof of Resident/Company Address of the Fund Manager – Scanned Copy")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp-items-group-header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Corporate Shareholder's Business Certificate & Articles of Association of the Fund Manager – Scanned Copy")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp-items-group-header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Business Profile & Articles of Association of the Proposed Fund Manager")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp-items-group-header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Shareholder Structure of the Proposed Fund Manager")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp-items-group-header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Latest Annual Financial Report of the Proposed Fund Manager and its Managed Fund(s) (if applicable)")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp-items-group-header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Proof of Registered Office Address of the Proposed Fund Manager – Scanned Copy")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp-items-group-header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Particulars of the Current secretary of the fund manager and its managed fund(s)")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp-items-group-header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" Particulars of the Finance and Tax In-charge, the Auditors of the Proposed Fund Manager and its Managed Fund(s) Particulars of the Proposed Company's Finance and Tax In-charge ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp-items-group-header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Authorization of applicant – Scanned copy")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp-items-group-header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Upload promotion related supporting, contact us if you need any clarification.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp-items-group-header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Ultimate Beneficial Owner(s)' ID/Business Certificate and Proof of Resident/Company Address")])]);

}]

export { render, staticRenderFns }